import axios from "axios";

export async function loginFunc(paramData) {
  const { data } = await axios.post(
    "https://gxauth.apimachine.com/gx/user/auth/login",
    paramData
  );
  return data;
}

export async function isEmailRegisteredInFinaceFunc(paramData) {
  const userDetails =
    paramData || localStorage.getItem("nvestBankLoginAccount");
  let email = userDetails;
  try {
    let { data } = await axios.get(
      `https://finace.apimachine.com/api/cpa/get?email=${email}`
    );
    return data;
  } catch (error) {
    console.log(error, "Login error");
  }
}
