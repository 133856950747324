import React from "react";
import classNames from "./home.module.scss";
import Navbar from "../../components/Navbar";
import Landing from "../../components/Home/Landing";
import OwlCarousal from "../../components/Home/OwlCarousal";
import Features from "../../components/Home/Features";
import Workflow from "../../components/Home/Workflows";
import Academy from "../../components/Home/Academy";
import Footer from "../../components/Home/Footer";
import Comparison from "../../components/Home/Comparison";
import Philosophy from "../../components/Home/Philosophy";
import Vision from "../../components/Home/Vision";
import CeoSection from "../../components/Home/Ceosection";
import Finaceverse from "../../components/Home/Finaceverse";
import CTOList from "../../components/Home/Ctolist";

const Home = () => {
  return (
    <div className={classNames.home}>
      <Navbar />
      <Landing />
      {/* <OwlCarousal /> */}
      <Features />
      <Workflow />
      <Finaceverse />
      <Philosophy />
      <Vision />
      <CeoSection />
      <CTOList />
      <Academy />
      {/* <Comparison /> */}
      <Footer />
      {/* <Analytics />
      <Auditors /> */}
    </div>
  );
};

export default Home;
