import React, {useState} from "react";
import classNames from "./common.module.scss";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import Client from "../../../assets/images/pages/CRMuser/Client.svg";
import CPAAdmin from "../../../assets/images/pages/CRMuser/CPAAdmin.svg";
import CPAEmployee from "../../../assets/images/pages/CRMuser/CPAEmployee.svg";
import IndependentAcc from "../../../assets/images/pages/CRMuser/IndependentAccountant.svg";

const CrmTopbar = () => {
  const [dropdownAddUser, setDropdownAddUser] = useState(false);
  const [typeUserAdd, setTypeUserAdd] = useState([
    { id: 1, mainName: "Client", imgSrc: Client },
    { id: 2, mainName: "CPA Admin", imgSrc: CPAAdmin },
    { id: 3, mainName: "CPA Employee", imgSrc: CPAEmployee },
    { id: 4, mainName: "Independent Accountant", imgSrc: IndependentAcc },
  ]);
  return (
    <>
      <div className={classNames.headerSuperAd}>
        <div className={classNames.searchDropdown}>
          All Users
          <IoMdArrowDropdown />
        </div>
        <div className={classNames.filterDropdown}>Filter</div>
        <div className={classNames.sortBy}>Sort by</div>
        <div className={classNames.searchDropdown}>
          Created Time
          <IoMdArrowDropdown />
        </div>
        <div className={classNames.searchDropdown}>
          <span
            style={{
              border: "2px solid #191F41",
              width: "18px",
              height: "10px",
            }}
          ></span>
          Export
          <IoMdArrowDropdown />
        </div>
        <div
          className={classNames.searchDropdown}
          style={{
            color: "#fff",
            backgroundColor: "#D6A86B",
          }}
          onClick={() => {
            setDropdownAddUser((prev) => !prev);
          }}
        >
          Add User
          {dropdownAddUser ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
        </div>
        {dropdownAddUser && (
          <div className={classNames.dropdownAddUserOpen}>
            <p className={classNames.addUserHeading}>Invite new user</p>
            <div className={classNames.inputLableAddUser}>
              <p>Create a temporary username</p>
              <input type="text" placeholder="Ex. Shorupan" />
            </div>
            <div className={classNames.inputLableAddUser}>
              <p>What is the person’s email?</p>
              <input type="email" placeholder="Email..." />
            </div>
            <div style={{ marginBottom: "10px" }}>
              <p style={{ marginBottom: "10px" }}>What type of user is this?</p>
              <div className={classNames.cardsTypeAddUserAll}>
                {typeUserAdd.map((types, i) => {
                  return (
                    <div
                      className={classNames.cardTypeAddUser}
                      key={types.id + i}
                    >
                      <p>{types.mainName}</p>
                      <img src={types.imgSrc} alt={types.mainName + "no_img"} />
                    </div>
                  );
                })}
              </div>
            </div>
            <button
              className={classNames.initiateInvite}
              onClick={() => setDropdownAddUser((prev) => !prev)}
            >
              Verify User
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default CrmTopbar;
