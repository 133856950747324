import React from "react";
import classNames from "./login.module.scss";
import { useHistory } from "react-router-dom";
//assets
import fullLogo from "../../assets/images/logo.svg";
import client from "../../assets/images/client.svg";
import cpa_admin from "../../assets/images/cpa_admin.svg";
import cpa_emp from "../../assets/images/cpa_emp.svg";
import ind_acc from "../../assets/images/ind_acc.svg";
const CreateFinaceAccountType = () => {
  const navigate = useHistory();

  return (
    <div className={classNames.loginPage}>
      <div className={classNames.finaceAccountTypeCard}>
        <div>
          <img
            src={fullLogo}
            alt="fullLogo"
            style={{ width: "100%" }}
            onClick={() => navigate.push("/")}
          />
        </div>
        <p style={{ margin: "2rem 0" }}>
          What type of Finance account do you want to create?
        </p>
        <div className={classNames.container}>
          <div className={classNames.row}>
            <div
              className={classNames.box}
              onClick={() => navigate.push("/client/profileCreation")}
            >
              <div className={classNames.contentWrapper}>
                <div className={classNames.textS}>Client</div>
                <div className={classNames.logoWrapper}>
                  <img src={client} style={{ marginTop: "1rem" }} />
                </div>
              </div>
            </div>
            <div
              className={classNames.box}
              onClick={() => navigate.push("/cpa/profileCreation")}
            >
              <div className={classNames.contentWrapper}>
                <div className={classNames.textS}>CPA Admin</div>
                <div className={classNames.logoWrapper}>
                  <img src={cpa_admin} style={{ marginTop: "1rem" }} />
                </div>
              </div>
            </div>
            <div
              className={classNames.box}
              onClick={() => navigate.push("/employee/profileCreation")}
            >
              <div className={classNames.contentWrapper}>
                <div className={classNames.textS}>CPA Employee</div>
                <div className={classNames.logoWrapper}>
                  <img src={cpa_emp} style={{ marginTop: "1rem" }} />
                </div>
              </div>
            </div>
          </div>
          <div className={classNames.row}>
            <div
              className={classNames.box}
              onClick={() => navigate.push("/independent/profileCreation")}
            >
              <div className={classNames.contentWrapper}>
                <div className={classNames.textS}>Independent Accountant</div>
                <div className={classNames.logoWrapper}>
                  <img src={ind_acc} style={{ marginTop: "1rem" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFinaceAccountType;
