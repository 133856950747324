import React from "react";
import Comparison from "../../components/Home/Comparison";
import Navbar from "../../components/Navbar";

const ComparisonPage = () => {
  return (
    <div>
      <Navbar />
      <Comparison />
    </div>
  );
};

export default ComparisonPage;
