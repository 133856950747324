import React, { useState } from "react";
import classNames from "./input.module.scss";
import { IoMdArrowDropdown } from "react-icons/io";
import Luca from "../../../assets/images/sidebar/logo/luca.svg";
import Hdfc from "./assets/hdfc.svg";
import Intuit from "./assets/intuit.svg";
import Amazon from "./assets/amazon.png";
import { Modal } from "antd";

const CpaInput = () => {
  const [bookModalOpen, setBookModalOpen] = useState(false);
  const [quickbooks, setQuickbooks] = useState("");
  const ac = (
    <div className={classNames.cardIntegration}>
      <img src={Amazon} alt="no_img" />
      <p>Amazon</p>
    </div>
  );
  return (
    <div className={classNames.mainSection}>
      <div className={classNames.leftPart}>
        <h4>Selected client</h4>
        <div className={classNames.clientUser}>
          <div className={classNames.clientPhoto}>
            <img src={Luca} alt="client_photo" />
          </div>
          <div>
            <p className={classNames.clientName}>Alan Smith</p>
            <p className={classNames.clientEmail}>alansmith@gmail.com</p>
          </div>
          <IoMdArrowDropdown />
        </div>
        <h4>Accounting softwares</h4>
        <div
          className={classNames.accSoftwareAll}
          onClick={() => {
            setBookModalOpen(true);
          }}
        >
          <div className={classNames.accSoftware}>
            <div className={classNames.accPhoto}>
              <img src={Intuit} alt="client_photo" />
            </div>
            <div>
              <p className={classNames.accName}>Intuit Quickbooks</p>
              <p className={classNames.accEmail}>alansmith@gmail.com</p>
            </div>
            <div className={classNames.statInteract}>
              <p className={classNames.accStatus}>Connected</p>
              <p className={classNames.accInteraction}>
                Last interaction: 01/01/2023 at 4:35 PM EST
              </p>
            </div>
          </div>
        </div>

        <Modal
          title="Add Quickbooks"
          centered
          open={bookModalOpen}
          onCancel={() => {
            setBookModalOpen(false);
            setQuickbooks("");
          }}
          footer={null}
          style={{
            maxHeight: "90vh",
            overflow: "scroll",
          }}
        >
          <div className={classNames.modalShow}>
            {quickbooks === "autoSync" ? (
              <>
                <p>
                  Before you connect you’re Quickbooks account, take a second to
                  customize the integration parameters to fit your needs
                </p>
                <div className={classNames.bookConnection}>
                  <div className={classNames.autoSyncFrequency}>
                    <p>
                      How frequently do you want the automatic sync to take
                      place?
                    </p>
                    <button>Daily</button>
                    <button>Weekly</button>
                    <button>Monthly</button>
                    <button>Custom</button>
                  </div>
                  <div className={classNames.autoSyncFrequency}>
                    <p>Where do you want us to get counterparty data from?</p>
                    <button>Quickbooks</button>
                    <button>Create custom database</button>
                  </div>
                  <div className={classNames.autoSyncFrequency}>
                    <p>
                      Where do you want us to get transaction categories data
                      from?
                    </p>
                    <button>Quickbooks</button>
                    <button>Create custom database</button>
                  </div>
                  <button onClick={() => setQuickbooks("connectedSync")}>
                    Add Quickbooks
                  </button>
                </div>
              </>
            ) : quickbooks === "connectedSync" ? (
              <>
                <div className={classNames.bookConnection}>
                  <div className={classNames.connectedImg}>
                    <img src={Intuit} alt="book_logo" />
                  </div>
                  <div className={classNames.APIinfo}>
                    <p>API Key</p>
                    <input type="text" placeholder="" />
                  </div>

                  <button
                    style={{ backgroundColor: "#D6A86B" }}
                    onClick={() => {
                      setQuickbooks("");
                      setBookModalOpen(false);
                    }}
                  >
                    Return To Dashboard
                  </button>
                  <button
                    onClick={() => {
                      setQuickbooks("errorSync");
                    }}
                  >
                    Create First Sync
                  </button>
                </div>
              </>
            ) : quickbooks === "errorSync" ? (
              <>
                <p>
                  We are only requesting view & write permissions. This does not
                  give us access to your private keys nor the ability to move
                  your funds.
                </p>
                <div className={classNames.bookConnection}>
                  <div className={classNames.autoSyncFrequency}>
                    <button
                      onClick={() => {
                        setQuickbooks("");
                        setBookModalOpen(false);
                      }}
                    >
                      Try connecting again
                    </button>
                    <button
                      onClick={() => {
                        setQuickbooks("");
                        setBookModalOpen(false);
                      }}
                    >
                      Raise a support ticket
                    </button>
                    <button
                      onClick={() => {
                        setQuickbooks("");
                        setBookModalOpen(false);
                      }}
                    >
                      Learn how to find API keys in Quickbooks
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <p>
                  We are only requesting view & write permissions. This does not
                  give us access to your private keys nor the ability to move
                  your funds.
                </p>
                <div className={classNames.bookConnection}>
                  <div className={classNames.switchAutoManual}>
                    <p>Automatic Sync</p>
                    <p>Manual Connection</p>
                  </div>
                  <div className={classNames.APIinfo}>
                    <p>API Key</p>
                    <input type="text" placeholder="" />
                  </div>
                  <div className={classNames.APIinfo}>
                    <p>API Secret</p>
                    <input type="text" placeholder="" />
                  </div>
                  <button onClick={() => setQuickbooks("autoSync")}>
                    Add Quickbooks
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal>

        <h4>Bank accounts</h4>
        <div className={classNames.accSoftwareAll}>
          <div className={classNames.accSoftware}>
            <div className={classNames.accPhoto}>
              <img src={Hdfc} alt="client_photo" />
            </div>
            <div>
              <p className={classNames.accName}>HDFC</p>
              <p className={classNames.accEmail}>alansmith@gmail.com</p>
            </div>
            <div className={classNames.statInteract}>
              <p className={classNames.accStatus}>Connected</p>
              <p className={classNames.accInteraction}>
                Last interaction: 01/01/2023 at 4:35 PM EST
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.rightPart}>
        <h4>Potential input integrations</h4>
        <div className={classNames.inputIntegrations}>
          <input type="text" placeholder="Search.." />
          <p>Over 15 more integrations</p>
          <div className={classNames.cardIntegrationAll}>
            <div className={classNames.cardIntegration}>
              <img src={Amazon} alt="no_img" />
              <p>Amazon</p>
            </div>
            {ac}
            {ac}
            {ac}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CpaInput;
