import React, { useCallback, useState } from "react";
import classNames from "./features.module.scss";

//assets
import { platformItems } from "../../../assets/data/mapItems";
import { IoIosArrowRoundForward } from "react-icons/io";
import { toast } from "react-toastify";
import YouTube from "react-youtube";

const Features = () => {
  const [selectedFeature, setSelectedFeature] = useState("Fin(AI)ssured");

  const handleMoreClick = useCallback((link) => {
    console.log("handle", link);
    if (link) {
      window.location.href = link;
    } else {
      toast.info("Coming soon⏳");
    }
  }, []);

  return (
    <section className={classNames.featurePage} id="solutions">
      <div className={classNames.feature}>
        <div className={classNames.platformTitle}>#Solutions</div>
        <div className={classNames.title}>
          <div>Our Suite Of Ai-Enabled Tools</div>
          {/* <div>Thing Of The Past</div> */}
        </div>
        <div className={classNames.desc}>
          We enhance essential accounting operations by automating and
          safeguarding all processes, from handling financial data management to
          reporting to stakeholders, ensuring accuracy and efficiency.
        </div>
        {window.innerWidth > 800 ? (
          <div className={classNames.platform}>
            <div className={classNames.leftContainer}>
              {platformItems?.map((eachItem, index) => {
                return (
                  <div
                    className={`${classNames.normalItem} ${
                      selectedFeature == eachItem?.name
                        ? classNames.selectedItem
                        : ""
                    }`}
                    style={{
                      background:
                        index === 0 && selectedFeature == eachItem?.name
                          ? "#D6A86B1A"
                          : index === 1 && selectedFeature == eachItem?.name
                          ? "#191F411A"
                          : index === 2 && selectedFeature == eachItem?.name
                          ? "#59A2DD1A"
                          : index === 3 && selectedFeature == eachItem?.name
                          ? "#FFBF911A"
                          : index === 4 && selectedFeature == eachItem?.name
                          ? "#983A781A"
                          : "",
                    }}
                    onClick={() => setSelectedFeature(eachItem?.name)}
                  >
                    {selectedFeature == eachItem?.name ? (
                      <div
                        className={classNames.fullItem}
                        style={{
                          "--link-hover-color":
                            selectedFeature == "Fin(AI)ssured"
                              ? "#D6A86B"
                              : selectedFeature === "Accountants.io"
                              ? "#191F41"
                              : selectedFeature === "Luca"
                              ? "#59A2DD"
                              : selectedFeature === "MyCabinet"
                              ? "#983A78"
                              : "",
                        }}
                      >
                        <div className={classNames.title}>{eachItem?.name}</div>
                        <div className={classNames.desc}>{eachItem?.desc}</div>
                        <div
                          className={classNames.linkBtn}
                          style={{
                            background: eachItem?.color ? eachItem?.color : "",
                          }}
                          onClick={() => handleMoreClick(eachItem?.link)}
                        >
                          <div>Learn More</div>
                          <span>
                            <IoIosArrowRoundForward />
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={classNames.itemTitle}
                        style={{
                          "--bg-hover-item": eachItem?.color,
                        }}
                      >
                        <div>{eachItem?.name}</div>
                        <span>+</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div
              className={classNames.rightContainer}
              style={{
                background:
                  selectedFeature == "Fin(AI)ssured"
                    ? "#D6A86B"
                    : selectedFeature === "Accountants.io"
                    ? "#191F41"
                    : selectedFeature === "Luca"
                    ? "#59A2DD"
                    : selectedFeature === "Accute"
                    ? "#FFBF91"
                    : selectedFeature === "MyCabinet"
                    ? "#983A78"
                    : "",
              }}
            >
              <div className={classNames.iframeBox}>
                {selectedFeature == "Fin(AI)ssured" ? (
                  <YouTubeEmbed videoId="2XVYtIu0H1Y" />
                ) : selectedFeature === "Accute" ? (
                  <YouTubeEmbed videoId="-JuaD4pLxr8" />
                ) : (
                  ""
                )}
              </div>
              {/* {Array.from({ length: 500 })?.map((eachItem, index) => {
              return <div key={"index rightContainer" + index}></div>;
            })} */}
            </div>
          </div>
        ) : (
          <PlatformMobile
            selectedFeature={selectedFeature}
            setSelectedFeature={setSelectedFeature}
          />
        )}
      </div>
    </section>
  );
};

export default Features;

const PlatformMobile = ({ selectedFeature, setSelectedFeature }) => {
  return (
    <div className={classNames.platformMobile}>
      <div className={classNames.leftContainer}>
        {platformItems?.map((eachItem, index) => {
          return (
            <div
              className={`${classNames.normalItem} ${
                selectedFeature == eachItem?.name ? classNames.selectedItem : ""
              }`}
              style={{
                background:
                  eachItem?.name === "Fin(AI)ssured"
                    ? "#D6A86B1A"
                    : eachItem?.name === "Accountants.io"
                    ? "#191F411A"
                    : eachItem?.name === "Luca"
                    ? "#59A2DD1A"
                    : "",
              }}
              onClick={() => setSelectedFeature(eachItem?.name)}
            >
              <div
                className={classNames.fullItem}
                style={{
                  "--link-hover-color":
                    selectedFeature == "Fin(AI)ssured"
                      ? "#D6A86B"
                      : selectedFeature === "Accountants.io"
                      ? "#191F41"
                      : selectedFeature === "Luca"
                      ? "#59A2DD"
                      : "",
                }}
              >
                <div className={classNames.title}>{eachItem?.name}</div>
                <div className={classNames.desc}>{eachItem?.desc}</div>
                <div
                  className={classNames.linkBtn}
                  style={{
                    background: eachItem?.color ? eachItem?.color : "",
                  }}
                >
                  <div>Learn More</div>
                  <span>
                    <IoIosArrowRoundForward />
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const YouTubeEmbed = ({ videoId }) => {
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
};
