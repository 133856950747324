import React from "react";
import classNames from "./landing.module.scss";

//assets
import lottie from "lottie-web";
import homeMasthead from "../../../assets/lottie/home-masthead-v3.json";
import { ReactComponent as Platform } from "../../../assets/images/pages/home/platform.svg";
import { LottieAnimation } from "../Auditors";
import { useHistory } from "react-router-dom";
import dotbg from "../../../assets/images/pages/home/dotbg.svg";
import landingGirl from "../../../assets/images/pages/home/landinggirl.svg";

//company assets
import accuteIcon from "../../../assets/images/pages/home/landing/companies/accute.svg";
import accountsIcon from "../../../assets/images/pages/home/landing/companies/accounts.svg";
import finaissuredIcon from "../../../assets/images/pages/home/landing/companies/finaissured.svg";
import lucaIcon from "../../../assets/images/pages/home/landing/companies/luca.svg";
import myCabinate from "../../../assets/images/pages/home/landing/companies/myCabinate.svg";

const Landing = () => {
  const navigate = useHistory();
  return (
    <section className={classNames.landing} id="aboutus">
      <div className={classNames.landingWrapper}>
        <div className={classNames.leftContainer}>
          <div className={classNames.contentBox}>
            <div className={classNames.title}>
              Welcome to FinACEverse: <br />
              Revolutionizing Accounting and Tax Solutions
            </div>
            <div className={classNames.imageParent}>
              <div>
                <a href="https://accute.io/" target="_blank">
                  <img src={accuteIcon} alt="accuteIcon" />
                </a>
              </div>
              <div>
                <a href="https://accountants.io/" target="_blank">
                  <img src={accountsIcon} alt="accountsIcon" />
                </a>
              </div>
              <div>
                {/* <a href="https://finace.io/" target="_blank"> */}
                <img src={finaissuredIcon} alt="finaissuredIcon" />
                {/* </a> */}
              </div>
              <div>
                {/* <a href="https://example.com/accute" target="_blank"> */}
                <img src={lucaIcon} alt="lucaIcon" />
                {/* </a> */}
              </div>
              <div>
                {/* <a href="https://example.com/accute" target="_blank"> */}
                <img src={myCabinate} alt="lucaIcon" />
                {/* </a> */}
              </div>
            </div>
            <div className={classNames.desc}>
              At FinACE, we are committed to transforming the landscape of
              accounting and tax practices with our suite of innovative SaaS
              products. Each of our offerings is designed to not only stand out
              individually but also work seamlessly together, creating a
              powerful ecosystem we proudly call the FinACEverse.
            </div>
            <div className={classNames.btnsContainer}>
              <div
                className={classNames.bookBtn}
                onClick={() => navigate.push("/waitinglist")}
              >
                Request Demo
              </div>
              <div
                className={classNames.trailBtn}
                onClick={() => navigate.push("/")}
              >
                Start Free Trial
              </div>
            </div>
          </div>
        </div>
        <div className={classNames.imageContainer}>
          <img src={landingGirl} alt="" />
          {/* <LottieAnimation animationData={homeMasthead} /> */}
        </div>
      </div>
      <img src={dotbg} alt="dotbg" className={classNames.dotBG} />
    </section>
  );
};

export default Landing;
