import React from 'react'
import './CTOCard.scss'
const CTOCard = ({ name, image , title}) => (
    <div className="cto-card">
      <img src={image} alt={name} className="cto-card__image" />
      <div className="cto-card__text">
        <h3 className="cto-card__name">{name}</h3>
        <p className="cto-card__title">{title}</p>
      </div>
    </div>
);
export default CTOCard