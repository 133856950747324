import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./users.module.scss";

//assets
import { ReactComponent as FilterIcon } from "../../../assets/images/filter.svg";
import { CopyBtn, CustomCheckbox } from "../../../components/Custom";
import { IoMdArrowDropdown, IoMdClose, IoMdSend } from "react-icons/io";
import { HiOutlineMicrophone } from "react-icons/hi2";
import { ReactComponent as SortArrows } from "../../../assets/images/icons/sort.svg";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../../context/Globalcontext";
import { useNetworkRequest } from "../../../hooks/networkRequest";
import { BsChevronBarDown, BsThreeDotsVertical } from "react-icons/bs";
import { FaChevronDown, FaMicrophone } from "react-icons/fa";

const CpaAdminDashboard = () => {
  const navigate = useHistory();
  const { actionBtns, setActionBtns } = useContext(GlobalContext);
  const { allValues: allLeads, allValuesLoading: allLeadsLoading } =
    useNetworkRequest({
      URL: "https://comms.globalxchange.io/gxb/apps/users/get?app_code=finace",
    });

  //page values
  const [filterOpen, setFilterOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // const [allLeadsLoading, setAllLeadsLoading] = useState(false);
  // const [allLeads, setAllLeads] = useState(tempLeads);
  const [allBrokeragesSearch, setAllBrokeragesSearch] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [subMenu, setSubMenu] = useState(null);
  // function onCloseSidebar() {
  //   if (sidebarOpen) {
  //     setSidebarOpen((prev) => !prev);
  //   }
  // }

  useEffect(() => {
    console.log(allLeads, "ljwefkjwebfkjwefkwjef");
  }, [allLeads]);

  const filterDropdownRef = useRef(null);
  const selectedItemTable = useRef(null);

  const filterItems = [
    {
      title: "See Client Details",
      icon: "",
      clickable: true,
      subMenu: "",
    },
    {
      title: "See inputs",
      icon: "",
      clickable: true,
      subMenu: "",
    },
    {
      title: "See outputs",
      icon: "",
      clickable: true,
      subMenu: "",
    },
    {
      title: "Create a report",
      icon: "",
      clickable: true,
      subMenu: "",
    },
    {
      title: "Contact",
      icon: "",
      clickable: true,
      subMenu: [
        {
          title: "Message",
          icon: "",
          clickable: true,
          subMenu: "",
        },
        {
          title: "Assign task",
          icon: "",
          clickable: true,
          subMenu: "",
        },
        {
          title: "Schedule meeting",
          icon: "",
          clickable: true,
          subMenu: "",
        },
        {
          title: "Email",
          icon: "",
          clickable: true,
          subMenu: "",
        },
      ],
    },
    {
      title: "Task Manager",
      icon: "",
      clickable: true,
      subMenu: "",
    },
  ];

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setSelectedRow(null);
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={classNames.mainNav}>
        <div className={classNames.leftNav}>
          <div className={classNames.btnWrapper} style={{ width: "146px" }}>
            <div>All Clients</div>
            <FaChevronDown style={{ width: "10px", height: "10px" }} />
          </div>
          <div className={classNames.btnWrapper1} style={{ width: "113px" }}>
            <img src={require("./assets/settings.svg").default} alt="" />
            <div style={{ paddingLeft: "5px" }}>Filter</div>
          </div>
        </div>
        <div className={classNames.rightNav}>
          <div style={{ paddingRight: "13px" }}>Sort By</div>
          <div className={classNames.btnWrapper} style={{ width: "146px" }}>
            <div>Created Time</div>
            <FaChevronDown style={{ width: "10px", height: "10px" }} />
          </div>
          <div className={classNames.btnWrapper} style={{ width: "146px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={require("./assets/export.svg").default} alt="" />
              <div style={{ paddingLeft: "5px" }}>Export</div>
            </div>
            <FaChevronDown style={{ width: "10px", height: "10px" }} />
          </div>
          <div
            className={classNames.btnWrapper}
            style={{
              width: "146px",
              background: "#D6A86B",
              color: "white",
            }}
          >
            <div>Add Client</div>
            <FaChevronDown style={{ width: "10px", height: "10px" }} />
          </div>
        </div>
      </div>
      <div className={classNames.crmUsers} style={{ background: "#FBFBFB" }}>
        <div className={classNames.allLeads}>
          {/* <div className={classNames.nav}>
            <div className={classNames.filterBtn} ref={filterDropdownRef}>
              <FilterIcon
                onClick={() => {
                  setFilterOpen(!filterOpen);
                }}
              />
              {filterOpen && (
                <div className={classNames.dropdownContent}>
                  {filterItems?.map((eachItem, index) => {
                    return (
                      <div
                        className={classNames.eachFilterItem}
                        key={eachItem + index}
                      >
                        {eachItem}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div> */}

          <div
            className={classNames.tableContainer}
            style={{ border: allLeads ? "" : "none" }}
          >
            <table style={{ minWidth: "100%" }}>
              <thead>
                <tr
                  style={{ gridTemplateColumns: "min-content repeat(5, 1fr)" }}
                >
                  <th></th>
                  <th>
                    Name <SortArrows />
                  </th>
                  <th>
                    Email <SortArrows />
                  </th>
                  <th>
                    Phone Number <SortArrows />
                  </th>
                  <th>
                    Status <SortArrows />
                  </th>
                  <th>
                    Internal Account Owner <SortArrows />
                  </th>
                </tr>
              </thead>
              <tbody>
                {allLeadsLoading ? (
                  <tr
                    key={"loading"}
                    style={{
                      gridTemplateColumns: "min-content repeat(5, 1fr)",
                    }}
                  >
                    <td>
                      <BsThreeDotsVertical />
                    </td>
                    <td>
                      <Skeleton width={100} height={20} />
                    </td>
                    <td>
                      <Skeleton width={100} height={20} />
                    </td>
                    <td>
                      <Skeleton width={100} height={20} />
                    </td>
                    <td>
                      <Skeleton width={100} height={20} />
                    </td>
                    <td>
                      <Skeleton width={100} height={20} />
                    </td>
                  </tr>
                ) : (
                  allLeads?.users?.length > 0 &&
                  allLeads?.users
                    ?.filter((row) => {
                      // Convert the row object values to an array and check if any value matches the search query
                      const values = Object?.values(row);
                      const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                      return values?.some((value) => {
                        if (typeof value === "string") {
                          return value?.toLowerCase()?.includes(searchQuery);
                        }
                        return false;
                      });
                    })
                    ?.sort((a, b) =>
                      a?.first_name?.localeCompare(b?.first_name)
                    )
                    ?.map((row, index) => (
                      <tr
                        key={row?.brokerage_name + index}
                        style={{
                          gridTemplateColumns: "min-content repeat(5, 1fr)",
                          pointerEvents: sidebarOpen ? "none" : "",
                        }}
                      >
                        <td
                          ref={filterDropdownRef}
                          //   style={{ pointerEvents: "none" }}
                          onClick={(e) => {
                            setSelectedRow(row);
                            // setActionBtns([row?.user_employee_id]);
                          }}
                        >
                          {/* <CustomCheckbox
                            actionBtns={actionBtns}
                            setActionBtns={setActionBtns}
                            id={row?.user_employee_id}
                            name={row?.first_name + " " + row?.last_name}
                          /> */}
                          <BsThreeDotsVertical />

                          {actionBtns?.includes(row?.user_employee_id) ||
                          selectedRow !== null ? (
                            <div
                              className={classNames.dropdownContent}
                              ref={selectedItemTable}
                              //   style={{ background: "red", zIndex: 9999 }}
                            >
                              {filterItems?.map((eachItem, index) => {
                                return (
                                  <div
                                    className={classNames.eachFilterItem}
                                    key={eachItem?.title + index}
                                    style={{
                                      pointerEvents: eachItem?.clickable
                                        ? ""
                                        : "none",
                                      opacity: eachItem?.clickable ? "" : "0.5",
                                    }}
                                    onClick={() => {
                                      if (
                                        eachItem?.clickable &&
                                        eachItem?.function
                                      ) {
                                        eachItem?.function();
                                      }
                                      setActionBtns([]);
                                    }}
                                  >
                                    <span>
                                      {eachItem.icon && <eachItem.icon />}
                                      {eachItem?.title}
                                    </span>{" "}
                                    {eachItem?.subMenu && <IoMdArrowDropdown />}
                                    {eachItem?.subMenu?.length > 0 &&
                                      Array.isArray(eachItem?.subMenu) && (
                                        <div
                                          className={`${classNames.dropdownContent} ${classNames.subDropdown}`}
                                        >
                                          {eachItem?.subMenu?.map(
                                            (eachItemSub, index) => {
                                              return (
                                                <div
                                                  className={
                                                    classNames.eachFilterItem
                                                  }
                                                  key={
                                                    eachItemSub?.title + index
                                                  }
                                                  style={{
                                                    pointerEvents:
                                                      eachItemSub?.clickable
                                                        ? ""
                                                        : "none",
                                                    opacity:
                                                      eachItemSub?.clickable
                                                        ? ""
                                                        : "0.5",
                                                  }}
                                                  onClick={() => {
                                                    setSubMenu(
                                                      eachItemSub?.title
                                                    );
                                                  }}
                                                >
                                                  {eachItemSub?.title}{" "}
                                                  {eachItemSub?.subMenu && (
                                                    <IoMdArrowDropdown />
                                                  )}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      )}
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {row?.name ? row?.name : ""}
                          {row?.name ? (
                            <CopyBtn copyText={row?.name ? row?.name : ""} />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {row?.email ? row?.email : ""}
                          {row?.email ? (
                            <CopyBtn copyText={row?.email ? row?.email : ""} />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {row?.phone ? row?.phone : ""}
                          {row?.phone ? (
                            <CopyBtn copyText={row?.phone ? row?.phone : ""} />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {row?.ref_affiliate ? row?.ref_affiliate : ""}
                          {row?.ref_affiliate ? (
                            <CopyBtn
                              copyText={
                                row?.ref_affiliate ? row?.ref_affiliate : ""
                              }
                            />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {row?.organization ? row?.organization : ""}
                          {row?.organization ? (
                            <CopyBtn
                              copyText={
                                row?.organization ? row?.organization : ""
                              }
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        {subMenu === "Message" && (
          <div className={classNames.rightSideDrawer}>
            <div className={classNames.header}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={classNames.avatar}></div>
                <div className={classNames.headerDetails}>
                  <div>Shorupan P</div>
                  <div>shorupan@gmail.com</div>
                </div>
              </div>
              <IoMdClose
                style={{ fontSize: "30px" }}
                onClick={(e) => {
                  setSubMenu(null);
                }}
              />
            </div>
            <div className={classNames.chatSection}>
              <div className={classNames.chatBubble}>
                <div>
                  Hi Shorupan, I wanted to know if you saw the report we sent
                  you. It would be great if we can get more data.
                </div>
                <div
                  style={{ float: "right", fontWeight: 600, fontSize: "12px" }}
                >
                  7:34 PM
                </div>
              </div>
            </div>
            <div className={classNames.chatInputSection}>
              <div className={classNames.inputWrapper}>
                <HiOutlineMicrophone />
                <input type="text" placeholder="Write Something..." />
                <div>
                  <IoMdSend />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CpaAdminDashboard;
