import React from "react";
import classNames from "./workflow.module.scss";

//assets
import { workflowItems } from "../../../assets/data/mapItems";

const Workflow = () => {
  const handleWorkFlowItemClick = () => {
    window.location.href = "https://www.finacegroup.com/ourservices";
  };
  return (
    <section className={classNames.workflowPage} id="services">
      <div className={classNames.workflow}>
        <div className={classNames.platformTitle}>#Services</div>
        <div className={classNames.title}>
          <div>Your Accounting Practice Needs</div>
          <div>More Than Just Software</div>
        </div>
        <div className={classNames.desc}>
          We enhance essential accounting operations by automating and
          safeguarding all processes, from handling financial data management to
          reporting to stakeholders, ensuring accuracy and efficiency.
        </div>
        <div className={classNames.trullionBox}>
          {workflowItems?.map((eachItem, index) => {
            return (
              <div
                className={classNames.eachBox}
                key={"trullionBox" + index}
                onClick={handleWorkFlowItemClick}
              >
                {/* <div className={classNames.imageBox}>
                  <img src={eachItem?.image} alt={"trullionImage" + index} />
                </div> */}
                <div className={classNames.contentBox}>{eachItem?.desc}</div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Workflow;
