import React from "react";
import classNames from "./academy.module.scss";

//assets
import { FaArrowRight } from "react-icons/fa";
import { academyItems } from "../../../assets/data/mapItems";

const Academy = () => {
  return (
    <section className={classNames.academyPage} id="academy">
      <div className={classNames.academy}>
        <div className={classNames.platformTitle}>#Academy</div>
        <div className={classNames.title}>
          Become An Accounting Professional Today
        </div>
        <div className={classNames.desc}>
          We enhance essential accounting operations by automating and
          safeguarding all processes, from handling financial data management to
          reporting to stakeholders, ensuring accuracy and efficiency.
        </div>
        <div className={classNames.academyBox}>
          {academyItems?.map((eachItem, index) => {
            return (
              <div className={classNames.eachBox} key={"academyBox" + index}>
                <div className={classNames.contentBox}>{eachItem?.title}</div>
                <div className={classNames.arrowSvg}>
                  <FaArrowRight />
                </div>
              </div>
            );
          })}
          <div className={classNames.eachBox}>
            <div
              className={`${classNames.contentBox} ${classNames.lastAcademyBox}`}
            >
              <div>Finace India</div>
              <div className={classNames.desc}>Click Here To Select State</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Academy;
