import React from "react";
import classNames from "./footer.module.scss";

import fullLogo from "../../../assets/images/pages/home/footerFullLogo.svg";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Footer = () => {
  const options = [
    { name: "Fin(AI)ssured", link: "" },
    { name: "Accountants.io", link: "https://accountants.io" },
    { name: "Luca", link: "" },
    { name: "Accute", link: "https://accute.io" },
    { name: "Mycabinet", link: "https://mycabinet.ai" },
  ];
  const handleOptionsClick = (link) => {
    if (link) window.location.href = link;
    else toast.info("Coming Soon");
  };
  return (
    <div className={classNames.footer}>
      <div className={classNames.mainBox}>
        <div className={classNames.head}>
          <img src={fullLogo} alt="fullLogo" />
        </div>
        <div className={classNames.allOptions}>
          <div>
            <div className={classNames.title}>Company</div>
            <div className={classNames.options}>
              {/* <div>
                <a href="#" style={{ color: "white" }}>
                  About us
                </a>
              </div> */}
              {/* <div>
                <Link to="/#" style={{ color: "white" }}>
                  Careers
                </Link>
              </div> */}
              <div>
                {/* <Link to="/#" style={{ color: "white" }}> */}
                <a
                  href="https://www.finacegroup.com/terms-of-use"
                  style={{ color: "#fff", textDecoration: "none" }}
                >
                  Terms & Conditions
                </a>
                {/* </Link> */}
              </div>
              {/* <div>
                <Link to="/#" style={{ color: "white" }}>
                  Disclaimers
                </Link>
              </div> */}
              <div>
                {/* <Link to="/#" style={{ color: "white" }}> */}
                <a
                  href="https://www.finacegroup.com/privacy-policy"
                  style={{ color: "#fff", textDecoration: "none" }}
                >
                  Privacy Policies
                </a>
                {/* </Link> */}
              </div>
              <div>
                {/* <Link to="/#" style={{ color: "white" }}> */}
                <a
                  href="https://www.finacegroup.com/refund-policy"
                  style={{ color: "#fff", textDecoration: "none" }}
                >
                  Cancellation & Refund Policy
                </a>
                {/* </Link> */}
              </div>
            </div>
          </div>
          <div>
            <div className={classNames.title}>Products</div>
            <div className={classNames.options}>
              {options.map((option, idx) => {
                return (
                  <div
                    key={idx}
                    onClick={() => handleOptionsClick(option.link)}
                  >
                    {option?.name}
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div className={classNames.title}>Resources</div>
            <div className={classNames.options}>
              <div>
                <a
                  href="https://www.finacegroup.com/blog"
                  style={{ color: "#fff", textDecoration: "none" }}
                >
                  Blog
                </a>
              </div>
              <div>
                <a
                  href="https://www.finacegroup.com/faqs"
                  style={{ color: "#fff", textDecoration: "none" }}
                >
                  FAQs
                </a>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "right" }}>
            <div className={classNames.title}>Contact</div>
            <div className={classNames.options}>
              {/* <div>
                <a
                  href="https://www.finacegroup.com/customersupport"
                  style={{ color: "#fff", textDecoration: "none" }}
                >
                  Sales enquiries - sales@finace.io
                </a>
              </div> */}
              {/* <div>
                <a
                  href="https://www.finacegroup.com/customersupport"
                  style={{ color: "#fff", textDecoration: "none" }}
                >
                  Support - support@finace.io
                </a>
              </div> */}
              <div>
                <a
                  href="https://www.finacegroup.com/customersupport"
                  style={{ color: "#fff", textDecoration: "none" }}
                >
                  Sales Info - info@finacegroup.com
                </a>
              </div>
              <div style={{ textDecoration: "none" }}>
                Sy 11, Krishe Emerald Kondapur Main Road, Laxmi Cyber City,
                Whitefields, Kondapur,
                <br /> Hyderabad, Telangana 500081
                {/* Plot No.177,Phase 2, Kamalapuri Colony,
                <div>Hyderabad, Telangana 500073</div> */}
              </div>
            </div>
          </div>
        </div>

        <div style={{ textAlign: "center", paddingTop: "3rem" }}>
          <div
            className={classNames.certifiedNumber}
            style={{ padding: "0px", fontWeight: 550 }}
          >
            Copyright © 2023 Finace.io. Owned and operated by Futurus FinACE
            Consulting Private Limited, All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
