import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./users.module.scss";

//assets
import { ReactComponent as FilterIcon } from "../../../assets/images/filter.svg";
import { CopyBtn, CustomCheckbox } from "../../../components/Custom";
// import {
//   IoMdArrowDropdown,
//   IoMdArrowDropup,
// } from "react-icons/io";
import { ReactComponent as SortArrows } from "../../../assets/images/icons/sort.svg";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../../context/Globalcontext";
import { useNetworkRequest } from "../../../hooks/networkRequest";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Modal } from "antd";
import { toastify } from "../../../assets/functions/toastify";
import SideMenu from "../Common/sideMenu";
import CrmTopbar from "../Common/crmTopbar";

const CrmUsers = () => {
  const navigate = useHistory();
  const { actionBtns, setActionBtns } = useContext(GlobalContext);
  const { allValues: allLeads, allValuesLoading: allLeadsLoading } =
    useNetworkRequest({
      URL: "https://comms.globalxchange.io/gxb/apps/users/get?app_code=finace",
    });

  //page values
  const [filterOpen, setFilterOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // const [allLeadsLoading, setAllLeadsLoading] = useState(false);
  // const [allLeads, setAllLeads] = useState(tempLeads);
  const [allBrokeragesSearch, setAllBrokeragesSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [expandDetails, setExpandDetails] = useState([]);
  const [dotMenus, setDotMenus] = useState([
    { id: 1, mainName: "See profile", shortName: "SP" },
    { id: 2, mainName: "Manage roles", shortName: "MR" },
    { id: 3, mainName: "Enable/disable account", shortName: "EA/DA" },
    { id: 4, mainName: "Reset password", shortName: "RP" },
  ]);
  const [modalOpen, setModalOpen] = useState(false);
  const [changeViewDot, setChangeViewDot] = useState([]);
  const [passChanging, setPassChanging] = useState([]);

  const dotMenuChange = (menuInfo) => {
    if (menuInfo.shortName !== "RP") {
      setModalOpen(true);
    }
    setChangeViewDot(menuInfo);
  };

  // function onCloseSidebar() {
  //   if (sidebarOpen) {
  //     setSidebarOpen((prev) => !prev);
  //   }
  // }

  const filterDropdownRef = useRef(null);
  const selectedItemTable = useRef(null);

  const filterItems = [];

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setFilterOpen(false);
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={classNames.sidemenuNcrm}>
      <SideMenu />
      <CrmTopbar />

      <div className={classNames.crmUsers}>
        <div className={classNames.allLeads}>
          {/* <div className={classNames.nav}>
          <div className={classNames.filterBtn} ref={filterDropdownRef}>
            <FilterIcon
              onClick={() => {
                setFilterOpen(!filterOpen);
              }}
            />
            {filterOpen && (
              <div className={classNames.dropdownContent}>
                {filterItems?.map((eachItem, index) => {
                  return (
                    <div
                      className={classNames.eachFilterItem}
                      key={eachItem + index}
                    >
                      {eachItem}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div> */}
          <div
            className={classNames.tableContainer}
            style={{ border: allLeads ? "" : "none" }}
          >
            <table style={{ minWidth: "100%" }}>
              <thead>
                <tr
                  style={{ gridTemplateColumns: "min-content repeat(5, 1fr)" }}
                >
                  <th></th>
                  <th>
                    Name <SortArrows />
                  </th>
                  <th>
                    Email <SortArrows />
                  </th>
                  <th>
                    Phone Number <SortArrows />
                  </th>
                  <th>
                    User Type <SortArrows />
                  </th>
                  <th>
                    Organization <SortArrows />
                  </th>
                </tr>
              </thead>
              <tbody>
                {allLeadsLoading ? (
                  <tr
                    key={"loading"}
                    style={{
                      gridTemplateColumns: "min-content repeat(5, 1fr)",
                    }}
                  >
                    <td>
                      {/* <CustomCheckbox /> */}
                      <BsThreeDotsVertical />
                    </td>
                    <td>
                      <Skeleton width={100} height={20} />
                    </td>
                    <td>
                      <Skeleton width={100} height={20} />
                    </td>
                    <td>
                      <Skeleton width={100} height={20} />
                    </td>
                    <td>
                      <Skeleton width={100} height={20} />
                    </td>
                    <td>
                      <Skeleton width={100} height={20} />
                    </td>
                  </tr>
                ) : (
                  allLeads?.users?.length > 0 &&
                  allLeads?.users
                    ?.filter((row) => {
                      // Convert the row object values to an array and check if any value matches the search query
                      const values = Object?.values(row);
                      const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                      return values?.some((value) => {
                        if (typeof value === "string") {
                          return value?.toLowerCase()?.includes(searchQuery);
                        }
                        return false;
                      });
                    })
                    ?.sort((a, b) =>
                      a?.first_name?.localeCompare(b?.first_name)
                    )
                    ?.map((row, index) => (
                      <tr
                        key={row?.brokerage_name + index}
                        style={{
                          gridTemplateColumns: "min-content repeat(5, 1fr)",
                          pointerEvents: sidebarOpen ? "none" : "",
                        }}
                      >
                        <td>
                          {/* <CustomCheckbox
                          actionBtns={actionBtns}
                          setActionBtns={setActionBtns}
                          id={row?.user_employee_id}
                          name={row?.first_name + " " + row?.last_name}
                        /> */}
                          {actionBtns?.includes(row?._id) ? (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (expandDetails?.includes(row?._id)) {
                                  setExpandDetails([]);
                                }
                                setActionBtns([]);
                                setChangeViewDot([]);
                                setPassChanging("");
                              }}
                            >
                              X
                            </span>
                          ) : (
                            <BsThreeDotsVertical
                              onClick={() => {
                                if (actionBtns?.includes(row?._id)) {
                                  setActionBtns([]);
                                } else {
                                  setActionBtns([row?._id]);
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                          {actionBtns?.includes(row?._id) ? (
                            <div
                              className={classNames.dropdownContent}
                              ref={selectedItemTable}
                              style={{
                                width:
                                  changeViewDot.shortName === "RP" &&
                                  "max-content",
                              }}
                            >
                              {changeViewDot.shortName === "RP" ? (
                                <div className={classNames.changePass}>
                                  <p className={classNames.changePassHeading}>
                                    Initiate reset password
                                  </p>
                                  {passChanging === "yesChange" ? (
                                    <>
                                      <p style={{ marginBottom: "10px" }}>
                                        Please enter the code we just sent to
                                        their email
                                      </p>
                                      <div className={classNames.modalBtnGrp}>
                                        <input type="text" placeholder="Code" />
                                        <button
                                          onClick={() =>
                                            setPassChanging("verifyCode")
                                          }
                                          style={{
                                            color: "#fff",
                                            backgroundColor: "#191F41",
                                            textAlign: "center",
                                          }}
                                        >
                                          Verify User
                                        </button>
                                      </div>
                                    </>
                                  ) : passChanging === "verifyCode" ? (
                                    <>
                                      <p style={{ marginBottom: "10px" }}>
                                        Here is the temporary password
                                      </p>
                                      <div className={classNames.modalBtnGrp}>
                                        <div className={classNames.tempPass}>
                                          asdfgasdfewr
                                        </div>
                                        <button
                                          onClick={() => {
                                            if (
                                              expandDetails?.includes(row?._id)
                                            ) {
                                              setExpandDetails([]);
                                            }
                                            setActionBtns([]);
                                            setChangeViewDot([]);
                                            setPassChanging("");
                                          }}
                                          style={{
                                            color: "#fff",
                                            backgroundColor: "#D6A86B",
                                            textAlign: "center",
                                          }}
                                        >
                                          Email to user
                                        </button>
                                        <button
                                          onClick={() => {
                                            if (
                                              expandDetails?.includes(row?._id)
                                            ) {
                                              setExpandDetails([]);
                                            }
                                            setActionBtns([]);
                                            setChangeViewDot([]);
                                            setPassChanging("");
                                            navigator.clipboard.writeText(
                                              "asdfgasdfewr"
                                            );
                                            toastify("Copied", "success");
                                          }}
                                          style={{
                                            color: "#fff",
                                            backgroundColor: "#191F41",
                                            textAlign: "center",
                                          }}
                                        >
                                          Copy to clipboard
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <p style={{ marginBottom: "10px" }}>
                                        Are you sure you want to reset{" "}
                                        {row?.name} password?
                                      </p>
                                      <div className={classNames.modalBtnGrp}>
                                        <button
                                          onClick={() =>
                                            setPassChanging("yesChange")
                                          }
                                        >
                                          Yes
                                        </button>
                                        <button
                                          onClick={() => {
                                            if (
                                              expandDetails?.includes(row?._id)
                                            ) {
                                              setExpandDetails([]);
                                            }
                                            setActionBtns([]);
                                            setChangeViewDot([]);
                                          }}
                                        >
                                          Never mind
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </div>
                              ) : (
                                <>
                                  {dotMenus.map((menuInfo, i) => {
                                    return (
                                      <div
                                        key={menuInfo.id + i}
                                        className={classNames.eachFilterItem}
                                        onClick={() => dotMenuChange(menuInfo)}
                                      >
                                        {menuInfo.mainName}
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                            </div>
                          ) : null}
                          <Modal
                            title="Disable account"
                            centered
                            open={modalOpen}
                            onCancel={() => setModalOpen(false)}
                            footer={null}
                          >
                            <div className={classNames.modalShow}>
                              <p>
                                Are you sure you want to disable {row?.name}{" "}
                                account?
                              </p>
                              <div className={classNames.modalBtnGrp}>
                                <button>Yes</button>
                                <button
                                  onClick={() => {
                                    if (expandDetails?.includes(row?._id)) {
                                      setExpandDetails([]);
                                    }
                                    setActionBtns([]);
                                    setChangeViewDot([]);
                                    setModalOpen(false);
                                  }}
                                >
                                  Never mind
                                </button>
                              </div>
                            </div>
                          </Modal>
                          {/* {actionBtns?.includes(row?.user_employee_id) && (
                            <div
                              className={classNames.dropdownContent}
                              ref={selectedItemTable}
                            >
                              {filterItems?.map((eachItem, index) => {
                                return (
                                  <div
                                    className={classNames.eachFilterItem}
                                    key={eachItem?.title + index}
                                    style={{
                                      pointerEvents: eachItem?.clickable
                                        ? ""
                                        : "none",
                                      opacity: eachItem?.clickable ? "" : "0.5",
                                    }}
                                    onClick={() => {
                                      if (
                                        eachItem?.clickable &&
                                        eachItem?.function
                                      ) {
                                        eachItem?.function();
                                      }
                                      setActionBtns([]);
                                    }}
                                  >
                                    <span>
                                      {eachItem.icon && <eachItem.icon />}
                                      {eachItem?.title}
                                    </span>{" "}
                                    {eachItem?.subMenu && <IoMdArrowDropdown />}
                                    {eachItem?.subMenu?.length > 0 &&
                                      Array.isArray(eachItem?.subMenu) && (
                                        <div
                                          className={`${classNames.dropdownContent} ${classNames.subDropdown}`}
                                        >
                                          {eachItem?.subMenu?.map(
                                            (eachItemSub, index) => {
                                              return (
                                                <div
                                                  className={
                                                    classNames.eachFilterItem
                                                  }
                                                  key={
                                                    eachItemSub?.title + index
                                                  }
                                                  style={{
                                                    pointerEvents:
                                                      eachItemSub?.clickable
                                                        ? ""
                                                        : "none",
                                                    opacity:
                                                      eachItemSub?.clickable
                                                        ? ""
                                                        : "0.5",
                                                  }}
                                                  onClick={() => {
                                                    navigate.push(eachItemSub?.link);
                                                  }}
                                                >
                                                  {eachItemSub?.title}{" "}
                                                  {eachItemSub?.subMenu && (
                                                    <IoMdArrowDropdown />
                                                  )}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      )}
                                  </div>
                                );
                              })}
                            </div>
                          )} */}
                        </td>
                        <td>
                          {row?.name ? row?.name : ""}
                          {row?.name ? (
                            <CopyBtn copyText={row?.name ? row?.name : ""} />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {row?.email ? row?.email : ""}
                          {row?.email ? (
                            <CopyBtn copyText={row?.email ? row?.email : ""} />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {row?.phone ? row?.phone : ""}
                          {row?.phone ? (
                            <CopyBtn copyText={row?.phone ? row?.phone : ""} />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {row?.ref_affiliate ? row?.ref_affiliate : ""}
                          {row?.ref_affiliate ? (
                            <CopyBtn
                              copyText={
                                row?.ref_affiliate ? row?.ref_affiliate : ""
                              }
                            />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {row?.organization ? row?.organization : ""}
                          {row?.organization ? (
                            <CopyBtn
                              copyText={
                                row?.organization ? row?.organization : ""
                              }
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          </div>

          <div className={classNames.paginationBar}>
            <button>Previous</button>
            <div className={classNames.allPagination}>
              {currentPage == 1
                ? Array.from({ length: 4 })?.map((eachItem, index) => {
                    return (
                      <div
                        key={"pagebtn" + index + currentPage}
                        className={`${classNames.eachBtn} ${
                          currentPage + index == currentPage
                            ? classNames.eachBtnCurrent
                            : ""
                        }`}
                        onClick={() => {
                          setCurrentPage(currentPage + index);
                        }}
                      >
                        {currentPage + index}
                      </div>
                    );
                  })
                : Array.from({ length: 4 })?.map((eachItem, index) => {
                    return (
                      <div
                        key={"pagebtn" + index + currentPage}
                        className={`${classNames.eachBtn} ${
                          currentPage + index - 1 == currentPage
                            ? classNames.eachBtnCurrent
                            : ""
                        }`}
                        onClick={() => {
                          setCurrentPage(currentPage + index - 1);
                        }}
                      >
                        {currentPage + index - 1}
                      </div>
                    );
                  })}
            </div>
            <button>Next</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrmUsers;
