import "./custom.scss";
import classNames from "./custom.module.scss";
import { toastify } from "../../assets/functions/toastify";
import { ReactComponent as CopyIcon } from "../../assets/images/icons/copy.svg";
import { GlobalContext } from "../../context/Globalcontext";
import { useContext, useEffect, useRef, useState } from "react";
import fullLogo from "../../assets/images/logo.svg";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { areAllFieldsFilled } from "../../assets/functions";

export const CustomCheckbox = ({
  actionBtns,
  setActionBtns,
  id,
  name,
  type,
}) => {
  const { setOverlayDeleteItemName } = useContext(GlobalContext);

  const handleCheckboxChange = () => {
    // console.log(id, " selected id");
    // if (type == "single") {
    //   setActionBtns([id]);
    // } else if (Array.isArray(actionBtns) && actionBtns?.includes(id)) {
    //   // If the ID is already in the array, remove it
    //   setActionBtns(actionBtns.filter((item) => item !== id));
    // } else {
    //   // If the ID is not in the array, add it
    //   setActionBtns([...actionBtns, id]);
    // }

    if (actionBtns?.includes(id)) {
      setActionBtns([]);
    } else {
      setActionBtns([id]);
    }

    setOverlayDeleteItemName(name);
  };

  // Check if the current checkbox's ID is present in the actionBtns array
  const isChecked = actionBtns?.includes(id);

  return (
    <label className="custom-checkbox">
      <input
        type="checkbox"
        id="myCheckbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <span className={`checkmark ${isChecked ? "checked" : ""}`}>
        {isChecked && (
          // <TiTick />
          <div className="checkedBox"></div>
        )}
      </span>
    </label>
  );
};

export const CopyBtn = ({ copyText }) => {
  return (
    <CopyIcon
      style={{
        width: "12px",
        height: "12px",
        cursor: "pointer",
        marginLeft: "7.5px",
        fontFamily: "Montserrat",
      }}
      onClick={() => {
        navigator.clipboard.writeText(copyText);
        toastify("Copied", "success");
      }}
      className="copy_icon"
    />
  );
};

export const EachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  indexx,
  calendarObjField,
  eachValuee,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  useEffect(() => {
    if (
      name === "selectedAppStaff" &&
      Array?.isArray(dropdown) &&
      dropdown?.length > 0
    ) {
      setState({
        NameOfTeam: dropdown[0]?.NameOfTeam,
        Icon: dropdown[0]?.Icon,
        TeamUsername: dropdown[0]?.TeamUsername,
      });
    }
  }, [dropdown]);

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        // minWidth: isOpen ? "250px" : "",
        zIndex: isOpen ? "3" : "",
        height: topTitle ? "6rem" : "3rem",
      }}
    >
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: topTitle ? "2rem" : "",
          maxHeight: isOpen ? "400px" : "100%",
          borderBottom: name === "selectedAppStaff" && !isOpen ? "none" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {name === "selectedAppStaff" && stateValue?.Icon ? (
              <img
                src={stateValue?.Icon}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : name === "selectedAppStaff" ? (
              <img
                src={fullLogo}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : (
              name === "selectedApp" && (
                <img
                  src={fullLogo}
                  alt={stateValue?.NameOfTeam}
                  style={{ height: "1.25rem" }}
                />
              )
            )}

            {name === "locationName" ? (
              stateValue?.locationName == "" ? (
                "Click to select"
              ) : (
                stateValue?.locationName
              )
            ) : name === "constultandName" ? (
              stateValue?.constultandName == "" ? (
                "Click to select"
              ) : (
                stateValue?.constultandName
              )
            ) : title ===
              "Which staff member do you want to make a specialist?" ? (
              stateValue.spaceName == "" ? (
                "Click to select"
              ) : (
                stateValue.spaceName
              )
            ) : name === "specialistList" ? (
              !stateValue?.speicalistName ? (
                "Click to select"
              ) : (
                stateValue?.speicalistName
              )
            ) : (
              <>
                {name === "selectedAppStaff" && stateValue?.NameOfTeam
                  ? stateValue?.NameOfTeam
                  : stateValue?.NameOfTeam && !name === "IndustriesId"
                  ? stateValue?.NameOfTeam
                  : selectedOption
                  ? selectedOption
                  : name === "IndustriesId"
                  ? "Click to select industries"
                  : title
                  ? title
                  : name === "selectedAppStaff"
                  ? "Select an Option"
                  : dropdown[0]?.NameOfTeam}
              </>
            )}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            <li
              style={{
                display: dropdown?.length <= 4 ? "none" : "",
              }}
            >
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
            </li>
            {type === "twoFields" && dropdown?.length > 0
              ? dropdown?.map((eachitem, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        // let objj = {
                        //   ...eachValuee,
                        //   [calendarObjField]: eachitem,
                        // };
                        // console.log(objj, "objj checking");
                        event.stopPropagation();
                        selectOption(eachitem);
                        setState(() => {
                          const updatedAnnualHolidays = [...stateValue[name]];
                          updatedAnnualHolidays[indexx] = {
                            ...updatedAnnualHolidays[indexx],
                            [calendarObjField]: eachitem,
                          };

                          return {
                            ...stateValue,
                            [name]:
                              (calendarObjField === "date" ||
                                calendarObjField === "month") &&
                              areAllFieldsFilled(updatedAnnualHolidays[indexx])
                                ? [
                                    ...updatedAnnualHolidays,
                                    { month: "", date: "" },
                                  ]
                                : updatedAnnualHolidays,
                          };
                        });
                      }}
                      key={eachitem + index}
                    >
                      {eachitem}
                    </li>
                  );
                })
              : title === "What is the parent service type?" &&
                dropdown?.length > 0
              ? dropdown?.map((each, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        // selectOption(
                        //   eachitem[mapVal?.name] +
                        //   " (" +
                        //   eachitem[mapVal?.name1] +
                        //   ")"
                        // );
                        setState({
                          ...stateValue,
                          constultandid: each?.consultationtypeid,
                          constultandName: each?.name,
                        });
                        setIsOpen(false);
                      }}
                      key={index}
                    >
                      {each?.name}
                    </li>
                  );
                })
              : title ===
                  "Which staff member do you want to make a specialist?" &&
                dropdown?.length > 0
              ? dropdown?.map((each, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        // selectOption(
                        //   eachitem[mapVal?.name] +
                        //   " (" +
                        //   eachitem[mapVal?.name1] +
                        //   ")"
                        // );
                        setState({
                          ...stateValue,
                          spaceName:
                            each?.TeamDetails?.[0]?.PlayerDetails?.[0]?.name,
                          spaceid: each?.teamplayerid,
                        });
                        setIsOpen(false);
                      }}
                      key={index}
                    >
                      <img
                        src={
                          each?.TeamDetails?.[0]?.PlayerDetails?.[0]?.ProfilePic
                        }
                      />
                      {each?.TeamDetails?.[0]?.PlayerDetails?.[0]?.name}
                    </li>
                  );
                })
              : title === "Which location will this specialist work at?" &&
                dropdown?.length > 0
              ? dropdown?.map((each, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        // selectOption(
                        //   eachitem[mapVal?.name] +
                        //   " (" +
                        //   eachitem[mapVal?.name1] +
                        //   ")"
                        // );
                        setState({
                          ...stateValue,
                          locationid: each?._id,
                          locationName: each?.name,
                        });
                        setIsOpen(false);
                      }}
                      key={index}
                    >
                      {each?.name}
                    </li>
                  );
                })
              : title === "Select specialist" && dropdown?.length > 0
              ? dropdown?.map((each, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        // selectOption(
                        //   eachitem[mapVal?.name] +
                        //   " (" +
                        //   eachitem[mapVal?.name1] +
                        //   ")"
                        // );
                        setState({
                          ...stateValue,
                          speicalistId: each?._id,
                          speicalistName:
                            each?.TeamDetails[0]?.PlayerDetails[0]?.name,
                        });
                        setIsOpen(false);
                      }}
                      key={index}
                    >
                      {each?.TeamDetails[0]?.PlayerDetails[0]?.name}
                    </li>
                  );
                })
              : title === "Which location will this service be offered at?" &&
                dropdown?.length > 0
              ? dropdown?.map((each, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        // selectOption(
                        //   eachitem[mapVal?.name] +
                        //   " (" +
                        //   eachitem[mapVal?.name1] +
                        //   ")"
                        // );
                        setState({
                          ...stateValue,
                          locationid: each?._id,
                          locationName: each?.name,
                        });
                        setIsOpen(false);
                      }}
                      key={index}
                    >
                      {each?.name}
                    </li>
                  );
                })
              : title === "Select the timezone" && dropdown?.length > 0
              ? dropdown?.map((eachitem, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        selectOption(
                          eachitem[mapVal?.name] +
                            " (" +
                            eachitem[mapVal?.name1] +
                            ")"
                        );
                        setState({
                          ...stateValue,
                          TimeZone: eachitem?.UTCDifference,
                          TimeZoneAbbrevation: eachitem?.Abbreviation,
                        });
                      }}
                      key={eachitem[mapVal?.name] + index}
                    >
                      {eachitem[mapVal?.name] +
                        " (" +
                        eachitem[mapVal?.name1] +
                        ")"}
                    </li>
                  );
                })
              : type === "obj" && dropdown?.length > 0
              ? dropdown
                  ?.filter((row) => {
                    return row[mapVal?.name]
                      ?.toLowerCase()
                      ?.includes(searchQuery?.toLowerCase());
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem[mapVal?.name]);
                          setState({
                            ...stateValue,
                            [name]: eachitem[stateVal],
                          });
                        }}
                        key={eachitem[mapVal?.name] + index}
                      >
                        {mapVal?.image && (
                          <img
                            src={eachitem[mapVal?.image]}
                            alt={eachitem[stateVal]}
                          />
                        )}
                        {eachitem[mapVal?.name]}
                      </li>
                    );
                  })
              : title === "All Countries"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    console.log(searchQueryy, "searchQueryysearchQueryy");
                    return row?.toLowerCase()?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState(eachitem);
                        }}
                        key={eachitem + index}
                        style={{
                          display: eachitem === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem}
                      </li>
                    );
                  })
              : (name === "Gender" ||
                  name === "groupType" ||
                  type === "single") &&
                dropdown?.length > 0
              ? dropdown?.map((eachitem, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        selectOption(eachitem);
                        setState({
                          ...stateValue,
                          [name]: eachitem,
                        });
                      }}
                      key={eachitem + index}
                    >
                      {eachitem}
                    </li>
                  );
                })
              : name === "IndustriesId" && dropdown?.length > 0
              ? dropdown?.map((eachitem, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        selectOption(eachitem?.Name);
                        setState({
                          ...stateValue,
                          [name]: eachitem?._id,
                        });
                      }}
                      key={eachitem?.Name + index}
                    >
                      {eachitem?.Name}
                    </li>
                  );
                })
              : dropdown?.length > 0 &&
                dropdown?.map((eachitem, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        selectOption(eachitem?.NameOfTeam);
                        if (name === "teamUsername") {
                          setState((prev) => {
                            return {
                              ...prev,
                              [name]: eachitem?.TeamUsername,
                            };
                          });
                        } else if (name === "selectedAppStaff") {
                          setState({
                            NameOfTeam: eachitem?.NameOfTeam,
                            Icon: eachitem?.Icon,
                            TeamUsername: eachitem?.TeamUsername,
                          });
                        } else {
                          setState({
                            ...stateValue,
                            [name]: {
                              NameOfTeam: eachitem?.NameOfTeam,
                              Icon: eachitem?.Icon,
                            },
                          });
                        }
                      }}
                      key={eachitem?.NameOfTeam + index}
                    >
                      <img src={eachitem?.Icon} alt={eachitem?.NameOfTeam} />
                      {eachitem?.NameOfTeam}
                    </li>
                  );
                })}
          </ul>
        )}
      </div>
    </div>
  );
};
