import React, { useEffect, useState } from "react";
import classNames from "./waitinglist.module.scss";

//assets
import fullLogo from "../../assets/images/logo.svg";
import { Link, useHistory } from "react-router-dom";
import { EachCustomDropdown } from "../../components/Custom";
import accuteLogo from "../../assets/images/pages/waitinglist/accute.svg";
import accountantsLogo from "../../assets/images/pages/waitinglist/accountants.svg";
import finaissuredLogo from "../../assets/images/pages/waitinglist/finaissured.svg";
import lucaLogo from "../../assets/images/pages/waitinglist/luca.svg";
import axios from "axios";
import { toastify } from "../../assets/functions/toastify";
import { firstNameValidation, onlyLettersRegex } from "../../assets/functions";

const WaitingList = () => {
  console.log("Waiting list coming");
  const navigate = useHistory();

  //page values
  const [currentStep, setCurrentStep] = useState("1"); //default is 1
  const [isFocused, setIsFocused] = useState(false);
  //API data
  const [allCountries, setAllCountries] = useState([]);
  //Value states
  const [waitingListValue, setWaitingListValue] = useState({
    teamusername: "kingdd",
    interestedproduct: [],
  });
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  //functions
  async function getAllCountries() {
    try {
      let response = await axios.get(
        "https://comms.globalxchange.io/coin/vault/countries/data/get"
      );

      if (response?.data?.countries?.length > 0) {
        setAllCountries(response?.data?.countries);
        console.log(response, "Get all countries response");
      }
    } catch (error) {
      console.log(error, "Get all countries error");
    }
  }

  async function submitLead() {
    setIsLoggingIn(true);
    try {
      let response = await axios.post(
        "https://finacebackend.marketsverse.com/api/addlead",
        waitingListValue
      );
      if (response?.data?.status) {
        toastify("Lead added successfully!", "success");
        setTimeout(() => {
          setIsLoggingIn(false);
          window?.location.reload();
        }, 1500);
      }
      console.log(response, "submitLead response");
    } catch (error) {
      toastify("Error while creating lead", "error");
      console.log(error?.message, "Submit lead response");
      setIsLoggingIn(false);
    }
  }

  //renderings

  useEffect(() => {
    getAllCountries();
  }, []);

  return (
    <div className={classNames.loginPage}>
      <div className={classNames.topNav}>
        <Link to="/">Go Back Home</Link>
      </div>
      <div className={classNames.loginCard}>
        <div
          className={classNames.leftCard}
          // style={{ paddingRight: "3rem" }}
        >
          <img
            src={fullLogo}
            alt="fullLogo"
            onClick={() => navigate.push("/")}
          />
          {currentStep == "2" ? (
            <>
              <div className={classNames.title}>
                Which of the Finace products are you interested in?
              </div>
              <div className={classNames.credentialsContainer}>
                <div className={classNames.allItemsContainer}>
                  <div
                    className={classNames.selectProducts}
                    style={{
                      border: waitingListValue?.interestedproduct?.includes(
                        "Accute"
                      )
                        ? "1px solid var(--font-color)"
                        : "",
                    }}
                    onClick={(event) => {
                      if (
                        Array.isArray(waitingListValue?.interestedproduct) &&
                        waitingListValue?.interestedproduct?.includes("Accute")
                      ) {
                        setWaitingListValue((prev) => {
                          return {
                            ...prev,
                            interestedproduct:
                              waitingListValue?.interestedproduct?.filter(
                                (i) => i !== "Accute"
                              ),
                          };
                        });
                      } else {
                        setWaitingListValue((prev) => {
                          return {
                            ...prev,
                            interestedproduct: [
                              ...prev?.interestedproduct,
                              "Accute",
                            ],
                          };
                        });
                      }
                    }}
                  >
                    <img src={accuteLogo} alt="accuteLogo" />
                    <div>
                      <div>Accute</div>
                      <div>ERP & Workflow automation for accounting firms</div>
                    </div>
                  </div>
                  <div
                    className={classNames.selectProducts}
                    style={{
                      border: waitingListValue?.interestedproduct?.includes(
                        "Accountants"
                      )
                        ? "1px solid var(--font-color)"
                        : "",
                    }}
                    onClick={(event) => {
                      if (
                        Array.isArray(waitingListValue?.interestedproduct) &&
                        waitingListValue?.interestedproduct?.includes(
                          "Accountants"
                        )
                      ) {
                        setWaitingListValue((prev) => {
                          return {
                            ...prev,
                            interestedproduct:
                              waitingListValue?.interestedproduct?.filter(
                                (i) => i !== "Accountants"
                              ),
                          };
                        });
                      } else {
                        setWaitingListValue((prev) => {
                          return {
                            ...prev,
                            interestedproduct: [
                              ...prev?.interestedproduct,
                              "Accountants",
                            ],
                          };
                        });
                      }
                    }}
                  >
                    <img src={accountantsLogo} alt="accountantsLogo" />
                    <div>
                      <div>Accountants.io</div>
                      <div>Marketplace for discovering accountants</div>
                    </div>
                  </div>

                  <div
                    className={classNames.selectProducts}
                    style={{
                      border: waitingListValue?.interestedproduct?.includes(
                        "Finassured"
                      )
                        ? "1px solid var(--font-color)"
                        : "",
                    }}
                    onClick={(event) => {
                      if (
                        Array.isArray(waitingListValue?.interestedproduct) &&
                        waitingListValue?.interestedproduct?.includes(
                          "Finassured"
                        )
                      ) {
                        setWaitingListValue((prev) => {
                          return {
                            ...prev,
                            interestedproduct:
                              waitingListValue?.interestedproduct?.filter(
                                (i) => i !== "Finassured"
                              ),
                          };
                        });
                      } else {
                        setWaitingListValue((prev) => {
                          return {
                            ...prev,
                            interestedproduct: [
                              ...prev?.interestedproduct,
                              "Finassured",
                            ],
                          };
                        });
                      }
                    }}
                  >
                    <img src={finaissuredLogo} alt="finaissuredLogo" />
                    <div>
                      <div>Fin(ai)ssured</div>
                      <div>AI powered booking automation</div>
                    </div>
                  </div>
                  <div
                    className={classNames.selectProducts}
                    style={{
                      border: waitingListValue?.interestedproduct?.includes(
                        "Luca"
                      )
                        ? "1px solid var(--font-color)"
                        : "",
                    }}
                    onClick={(event) => {
                      if (
                        Array.isArray(waitingListValue?.interestedproduct) &&
                        waitingListValue?.interestedproduct?.includes("Luca")
                      ) {
                        setWaitingListValue((prev) => {
                          return {
                            ...prev,
                            interestedproduct:
                              waitingListValue?.interestedproduct?.filter(
                                (i) => i !== "Luca"
                              ),
                          };
                        });
                      } else {
                        setWaitingListValue((prev) => {
                          return {
                            ...prev,
                            interestedproduct: [
                              ...prev?.interestedproduct,
                              "Luca",
                            ],
                          };
                        });
                      }
                    }}
                  >
                    <img src={lucaLogo} alt="lucaLogo" />
                    <div>
                      <div>Luca</div>
                      <div>Ai powered assistant for accounting tasks</div>
                    </div>
                  </div>
                </div>
                <div className={classNames.buttonsContainer}>
                  <button
                    className={classNames.blueBtn}
                    style={{
                      opacity: isLoggingIn ? "0.5" : "",
                      pointerEvents: isLoggingIn ? "none" : "",
                    }}
                    onClick={submitLead}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={classNames.title}>
                Join Our Waiting List And Get Early Product Updates
              </div>
              <div className={classNames.credentialsContainer}>
                <div className={classNames.sideByContainer}>
                  <input
                    className={classNames.inputContainer}
                    placeholder="First name"
                    type="text"
                    value={waitingListValue?.firstName}
                    onChange={(event) => {
                      if (firstNameValidation(event?.target?.value)) {
                        setWaitingListValue((prev) => {
                          return { ...prev, firstName: event?.target?.value };
                        });
                      }
                    }}
                    onKeyDown={(event) => {
                      if (
                        event?.target?.value.length == 1 &&
                        event.key === "Backspace"
                      ) {
                        setWaitingListValue({
                          ...waitingListValue,
                          firstName: "",
                        });
                      }
                    }}
                  />
                  <input
                    className={classNames.inputContainer}
                    placeholder="Last name"
                    type="text"
                    value={waitingListValue?.lastName}
                    onChange={(event) => {
                      const onlyLetters = /^[A-Za-z\s\.]+$/;

                      if (
                        (onlyLetters?.test(event?.target?.value) ||
                          event?.target?.value === "") &&
                        event?.target?.value.length <= 30
                      ) {
                        console.log("Coming inside value");
                        setWaitingListValue({
                          ...waitingListValue,
                          lastName: event?.target?.value,
                        });
                      }
                    }}
                    onKeyDown={(event) => {
                      if (
                        event?.target?.value.length == 1 &&
                        event.key === "Backspace"
                      ) {
                        setWaitingListValue({
                          ...waitingListValue,
                          lastName: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className={classNames.sideByContainer}>
                  <input
                    className={classNames.inputContainer}
                    placeholder="Email"
                    type="email"
                    value={waitingListValue?.email}
                    style={{
                      border:
                        !waitingListValue?.email?.length <= 50 &&
                        !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{1,3}$/?.test(
                          waitingListValue?.email
                        ) &&
                        isFocused
                          ? "1px solid red"
                          : "",
                    }}
                    onChange={(event) => {
                      setWaitingListValue((prev) => {
                        return { ...prev, email: event?.target?.value };
                      });
                    }}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onKeyDown={(event) => {
                      if (
                        event?.target?.value.length == 1 &&
                        event.key === "Backspace"
                      ) {
                        setWaitingListValue({
                          ...waitingListValue,
                          email: "",
                        });
                      }
                    }}
                  />
                  <input
                    className={classNames.inputContainer}
                    placeholder="Phone Number"
                    type="text"
                    value={waitingListValue?.phone}
                    onChange={(event) => {
                      if (
                        event?.target?.value.length <= 10 &&
                        /^-?\d+$/.test(event?.target?.value)
                      ) {
                        setWaitingListValue({
                          ...waitingListValue,
                          phone: event?.target?.value,
                        });
                      }
                    }}
                    onKeyDown={(event) => {
                      if (
                        event?.target?.value.length == 1 &&
                        event.key === "Backspace"
                      ) {
                        setWaitingListValue({
                          ...waitingListValue,
                          phone: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className={classNames.sideByContainer}>
                  <div>
                    <EachCustomDropdown
                      dropdown={allCountries}
                      name="country"
                      title="Country"
                      type="obj"
                      stateVal="name"
                      mapVal={{ image: "image", name: "name" }}
                      stateValue={waitingListValue}
                      setState={setWaitingListValue}
                    />
                  </div>
                  <input
                    className={classNames.inputContainer}
                    placeholder="State"
                    type="text"
                    value={waitingListValue?.state}
                    onChange={(event) => {
                      setWaitingListValue((prev) => {
                        return { ...prev, state: event?.target?.value };
                      });
                    }}
                  />
                </div>
                <div className={classNames.sideByContainer}>
                  <input
                    className={classNames.inputContainer}
                    placeholder="City"
                    type="text"
                    value={waitingListValue?.city}
                    onChange={(event) => {
                      setWaitingListValue((prev) => {
                        return { ...prev, city: event?.target?.value };
                      });
                    }}
                  />
                  <input
                    className={classNames.inputContainer}
                    placeholder="Street"
                    type="text"
                    value={waitingListValue?.street}
                    onChange={(event) => {
                      setWaitingListValue((prev) => {
                        return { ...prev, street: event?.target?.value };
                      });
                    }}
                  />
                </div>
                <div className={classNames.sideByContainer}>
                  <input
                    className={classNames.inputContainer}
                    placeholder="Company"
                    type="text"
                    value={waitingListValue?.company}
                    onChange={(event) => {
                      setWaitingListValue((prev) => {
                        return { ...prev, company: event?.target?.value };
                      });
                    }}
                  />
                  <input
                    className={classNames.inputContainer}
                    placeholder="Industry"
                    type="text"
                    value={waitingListValue?.industry}
                    onChange={(event) => {
                      if (onlyLettersRegex?.test(event?.target?.value)) {
                        setWaitingListValue((prev) => {
                          return { ...prev, industry: event?.target?.value };
                        });
                      }
                    }}
                    onKeyDown={(event) => {
                      if (
                        event?.target?.value.length === 1 &&
                        event.key === "Backspace"
                      ) {
                        setWaitingListValue({
                          ...waitingListValue,
                          industry: "",
                        });
                      }
                    }}
                  />
                </div>

                <div className={classNames.buttonsContainer}>
                  <button
                    className={classNames.blueBtn}
                    style={{
                      opacity:
                        !waitingListValue?.email?.length <= 50 &&
                        !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{1,3}$/?.test(
                          waitingListValue?.email
                        )
                          ? "0.5"
                          : isLoggingIn
                          ? "0.5"
                          : "",
                      pointerEvents:
                        !waitingListValue?.email?.length <= 50 &&
                        !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{1,3}$/?.test(
                          waitingListValue?.email
                        )
                          ? "none"
                          : isLoggingIn
                          ? "none"
                          : "",
                    }}
                    onClick={() => setCurrentStep("2")}
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default WaitingList;
