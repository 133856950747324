import clientIcon from "../images/menu/clients.svg";
import firmadminIcon from "../images/menu/firmadmin.svg";
import firmemployeesIcon from "../images/menu/firmemployees.svg";
import accountantsIcon from "../images/menu/accountants.svg";
import adminIcon from "../images/menu/admin.svg";
import affiliateIcon from "../images/registration/logo/affiliate.svg";
import rocketIcon from "../images/registration/logo/rocket.svg";
import searchIcon from "../images/search.svg";

//workflow
import controlsIcon from "../images/pages/home/workflow/controls.svg";
import connectIcon from "../images/pages/home/workflow/connect.svg";
import dataExtractionIcon from "../images/pages/home/workflow/data-extraction.svg";
import metricsIcon from "../images/pages/home/workflow/metrics.svg";
import stopIcon from "../images/pages/home/workflow/stop.svg";
import presentationIcon from "../images/pages/home/workflow/presentation.svg";

//comparison
import comparisonIcon from "../images/pages/home/comparison/trullion.svg";

export const mainMenuItems = [
  { name: "Clients", image: clientIcon, subMenuItem: "Fin(AI)ssured" },
  { name: "CPA Admin", image: firmadminIcon, subMenuItem: "Fin(AI)ssured" },
  {
    name: "CPA Employees",
    image: firmemployeesIcon,
    subMenuItem: "Fin(AI)ssured",
  },
  { name: "Accountants", image: accountantsIcon, subMenuItem: "Fin(AI)ssured" },
  { name: "Admin", image: adminIcon, subMenuItem: "CRM" },
];

export const subMenuItemsClients = [
  { name: "Fin(AI)ssured", link: "/clients/finaceured/walletscan" },
  { name: "Bank Accounts" },
  { name: "TPA Integrations" },
  { name: "Projects" },
  { name: "Support" },
];

export const subMenuItemsCPAAdmin = [
  { name: "Fin(AI)ssured", link: "/cpaadmin/finace/dashboard" },
  { name: "Employees" },
  { name: "Workflow Automation" },
  { name: "CRM" },
  { name: "Services" },
  { name: "Hire/Outsource" },
  { name: "Marketplace" },
  { name: "Company Profile" },
];

export const subMenuItemsCPAEmployees = [
  { name: "Fin(AI)ssured" },
  { name: "Projects" },
  { name: "CRM" },
  { name: "Workflow Automation" },
  { name: "Support" },
];

export const subMenuItemsAccountants = [
  { name: "Fin(AI)ssured" },
  { name: "Projects" },
  { name: "CRM" },
  { name: "Workflow Automation" },
  { name: "Services" },
  { name: "Accountant Profile" },
];

export const subMenuItemsAdmin = [
  { name: "CRM", link: "/admin/crm/users" },
  { name: "Overview" },
  { name: "Support" },
];

export const registrationItems = [
  {
    image: affiliateIcon,
    title: "Via An Affiliate",
    desc: "One of other many people who love Finace sent you here",
  },
  {
    image: rocketIcon,
    title: "I Am Pre-Registered",
    desc: "I am an existing client of a firm who on Finace",
  },
  {
    image: searchIcon,
    title: "I Got Here By Myself",
    desc: "Google, social media, etc",
  },
];

export const platformItems = [
  {
    name: "Fin(AI)ssured",
    desc: "We enhance essential accounting operations by automating and safeguarding all processes, from handling financial data management to reporting to stakeholders, ensuring accuracy..",
    color: "#D6A86B",
  },
  {
    name: "Accountants.io",
    desc: "Accountants.io is a global online marketplace where accountants and tax practitioners can discover assignments tailored to their expertise. This platform connects professionals with clients worldwide, expanding opportunities and fostering a network of excellence.",
    color: "#191F41",
    link: "https://accountants.io/",
  },
  {
    name: "Luca",
    desc: "Meet Luca, our AI-based bot designed to answer clients' tax and accounting questions with precision. Luca supports CPA firms, accountants, and tax practitioners in brainstorming strategies and planning assignments, streamlining client interactions and enhancing service delivery.",
    color: "#59A2DD",
  },
  {
    name: "Accute",
    desc: "Accute is an AI-driven workflow automation tool that helps manage tasks promptly. Acting as a CRM, it provides regular updates to clients, ensuring transparency and enhancing the efficiency of accounting and tax practices.",
    color: "#FFBF91",
    link: "https://accute.io/",
  },
  {
    name: "MyCabinet",
    desc: "Mycabinet offers a secure portal for clients to share sensitive documents with their accountants. With view-only access features, it ensures that critical business data remains protected while being easily accessible to authorized professionals.",
    color: "#983A78",
    link: "https://mycabinet.ai/",
  },
];

export const workflowItems = [
  { image: controlsIcon, desc: "Bookkeeping" },
  {
    image: connectIcon,
    desc: "Year-end compliance",
  },
  {
    image: dataExtractionIcon,
    desc: "Reconciliations",
  },
  { image: metricsIcon, desc: "Software conversion & integration" },
  {
    image: stopIcon,
    desc: "Individual & Business Tax Filings/Planning",
  },
  {
    image: presentationIcon,
    desc: "Payroll Processing",
  },
];

export const academyItems = [
  { title: "Canada" },
  {
    title: "United States",
  },
  {
    title: "United Kingdom",
  },
  { title: "UAE" },
];
export const comparisonItems = [
  { title: "Finace vs Auditoria", icon: comparisonIcon },
  { title: "Finace vs Auditoria", icon: comparisonIcon },
  { title: "Finace vs Auditoria", icon: comparisonIcon },
  { title: "Finace vs Auditoria", icon: comparisonIcon },
  { title: "Finace vs Auditoria", icon: comparisonIcon },
  { title: "Finace vs Auditoria", icon: comparisonIcon },
];

export const analyticsItems = [
  {
    title: "Flawless financial oversight",
    desc: "Enjoy 100% confidence in your numbers, know exactly what you team is working on, and protect your company from unplanned risk.",
  },
  {
    title: "Accurate, compliant reporting workflows",
    desc: "Use modern reporting methods for Rev Rec, Lease Accounting & more while meeting the global compliance requirements automatically.",
  },
  {
    title: "Smarter reporting and analytics",
    desc: "Enable the best business outcomes with automated, configurable reporting and unique insights into your financial data.",
  },
  {
    title: "Level up reporting.",
    desc: "Eliminate repetitive tasks and human error with automated data workflows, AI-enabled verification, and anomaly detection for a streamlined close.",
  },
];
