import ceoImage from "../../../assets/images/pages/home/landing/image/ceo.png";
import classNames from "./ceosection.module.scss";
const CeoSection = () => {
  return (
    <div className={classNames.wrapper}>
      <div className={classNames.imgContainer}>
        <img src={ceoImage} alt="CEO" />
      </div>
      <div className={classNames.textContainer}>
        {/* <div className={classNames.ceoText}> */}
        <h2 className={classNames.title}>A Word From Our CEO</h2>
        <p className={classNames.desc}>
          "It&apos;s either FinACE or someone else. The executor might change,
          but the result remains the same. The future of accounting is AI, and
          the future is NOW!"
        </p>
        <p className={classNames.ceoName}>Vithal V</p>
        {/* </div> */}
      </div>
    </div>
  );
};

export default CeoSection;
