import classNames from "./vision.module.scss";
import image1 from "../../../assets/images/pages/home/landing/image/Rectangle74.png";
import image2 from "../../../assets/images/pages/home/landing/image/Rectangle75.png";
import image3 from "../../../assets/images/pages/home/landing/image/Rectangle76.png";
import image4 from "../../../assets/images/pages/home/landing/image/Rectangle77.png";
const Vision = () => {
  return (
    <section className={classNames.workflowPage} id="services">
      <div className={classNames.workflow}>
        <div className={classNames.platformTitle}>#Vision</div>
        <div className={classNames.title}>
          <div>FinACE: Pioneering the Future of</div>
          <div>Accounting</div>
        </div>
        <div className={classNames.desc}>
          Through our suite of innovative products, FinACE is dedicated to
          supporting this transformation. Each product in the FinACEverse is
          designed to integrate seamlessly, providing accountants with the tools
          they need to become AI Trainers and redefine their roles:
        </div>
        <div className={classNames.imageFlex}>
          <div
            className={[classNames.imageText, classNames.imageTextBlue].join(
              " "
            )}
          >
            <img src={image1} alt="image1" />
            <div className={classNames.textOverImage}>
              <h2>Empowerment</h2>
              <h2>through AI</h2>
              <div className={classNames.hoverText}>
                <p>
                  By training AI systems, accountants can automate routine
                  tasks, allowing them to focus on higher-level strategic
                  planning and advisory roles. This shift will not only enhance
                  productivity but also enable accountants to deliver greater
                  value to their clients.
                </p>
              </div>
            </div>
          </div>
          <div
            className={[classNames.imageText, classNames.imageTextYellow].join(
              " "
            )}
          >
            <img src={image2} alt="image2" />
            <div className={classNames.textOverImage}>
              <h2>Enhanced Accuracy</h2>
              <h2>and Efficiency</h2>
              <div className={classNames.hoverText}>
                <p>
                  AI-driven automation minimizes the risk of human error,
                  ensuring more accurate financial records and compliance.
                  Accountants can oversee and refine these AI processes,
                  continually improving their effectiveness and to deliver
                  greater value to their clients.
                </p>
              </div>
            </div>
          </div>
          <div
            className={[classNames.imageText, classNames.imageTextBlue].join(
              " "
            )}
          >
            <img src={image3} alt="image3" />
            <div className={classNames.textOverImage}>
              <h2>Strategic Advisory</h2>
              <h2>Roles</h2>
              <div className={classNames.hoverText}>
                <p>
                  With routine tasks automated, accountants can dedicate more
                  time to analyzing data, providing insights, and advising
                  clients on financial strategies. This transformation will
                  position accountants as indispensable strategic partners in
                  business decision-making.
                </p>
              </div>
            </div>
          </div>
          <div
            className={[classNames.imageText, classNames.imageTextYellow].join(
              " "
            )}
          >
            <img src={image4} alt="image4" />
            <div className={classNames.textOverImage}>
              <h2>Continuous Learning</h2>
              <h2>and Adaptation</h2>
              <div className={classNames.hoverText}>
                <p>
                  As AI Trainers, accountants will engage in continuous
                  learning, staying updated with the latest advancements in
                  technology and AI. This commitment to innovation will ensure
                  they remain at the cutting edge of the accounting profession.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Vision;
