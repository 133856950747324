import React, { useEffect, useState } from "react";
import classNames from "./navbar.module.scss";
import { useHistory } from "react-router-dom";

//assets
import { LuMenu } from "react-icons/lu";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import logoWithName from "../../assets/images/logowithname.svg";

const Navbar = () => {
  const navigate = useHistory();
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const [hamburgerMenu, setHamburgerMenu] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the window has scrolled beyond a certain threshold
      const scrollThreshold = 5; // Adjust this value based on your design
      const isScrolled = window.scrollY > scrollThreshold;

      // Update the state to determine whether the navbar should be fixed
      setIsNavbarFixed(isScrolled);
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array means this effect runs once after the initial render

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      sections.forEach((section) => {
        const top = section.offsetTop;
        const bottom = top + section.clientHeight;

        if (scrollPosition >= top && scrollPosition <= bottom) {
          setActiveSection(section.id);
          console.log("Set section", section.id);
        }
      });
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Trigger initial scroll check
    handleScroll();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  const handleNavLinkClick = (id) => {
    const targetSection = document.getElementById(id);

    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleScroll = (e) => {
      if (hamburgerMenu) {
        e.preventDefault();
      }
    };

    if (hamburgerMenu) {
      // Add event listener when hamburgerMenu is true
      window.addEventListener("scroll", handleScroll);
      // Disable scrolling by setting overflow to hidden
      document.body.style.overflow = "hidden";
    } else {
      // Remove event listener when hamburgerMenu is false
      window.removeEventListener("scroll", handleScroll);
      // Enable scrolling by setting overflow to auto
      document.body.style.overflow = "auto";
    }

    // Cleanup the event listener and reset overflow when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.body.style.overflow = "auto";
    };
  }, [hamburgerMenu]);

  return (
    <div
      className={`${classNames.navbar} ${
        isNavbarFixed ? classNames.fixedNavbar : ""
      }`}
    >
      <div className={classNames.navbarWrapper}>
        <div
          className={classNames.logo}
          onClick={() => {
            navigate.push("/");
          }}
        >
          <img
            src={logoWithName}
            alt="logoWithName"
            className={classNames.navLogo}
          />
        </div>
        <div className={classNames.navigations}>
          <div
            onClick={() => handleNavLinkClick("aboutus")}
            style={{
              fontWeight: activeSection == "aboutus" ? "550" : "",
              textDecoration: activeSection == "aboutus" ? "none" : "",
              color: activeSection == "aboutus" ? "var(--font-color)" : "",
            }}
          >
            About Us
          </div>
          <div
            onClick={() => handleNavLinkClick("solutions")}
            style={{
              fontWeight: activeSection == "solutions" ? "550" : "",
              textDecoration: activeSection == "solutions" ? "none" : "",
              color: activeSection == "solutions" ? "var(--font-color)" : "",
            }}
          >
            Solutions
          </div>
          <div
            onClick={() => handleNavLinkClick("services")}
            style={{
              fontWeight: activeSection == "services" ? "550" : "",
              textDecoration: activeSection == "services" ? "none" : "",
              color: activeSection == "services" ? "var(--font-color)" : "",
            }}
          >
            Services
          </div>
          <div
            style={{
              fontWeight: activeSection == "academy" ? "550" : "",
              textDecoration: activeSection == "academy" ? "none" : "",
              color: activeSection == "academy" ? "var(--font-color)" : "",
            }}
            onClick={() => handleNavLinkClick("academy")}
          >
            Academy
          </div>
          {/* <div
            style={{
              fontWeight: activeSection == "comparison" ? "550" : "",
              textDecoration: activeSection == "comparison" ? "none" : "",
              color: activeSection == "comparison" ? "var(--font-color)" : "",
            }}
            onClick={() => {
              // handleNavLinkClick("comparison");
              navigate.push("/comparison");
            }}
          >
            Comparison
          </div> */}
        </div>
        <div className={classNames.navButtons}>
          <div onClick={() => navigate.push("/login")}>Log in</div>
          <div onClick={() => navigate.push("/waitinglist")}>Join WaitList</div>
        </div>
        <div
          className={classNames.navHamberger}
          onClick={() => setHamburgerMenu((prev) => !prev)}
        >
          {hamburgerMenu ? <IoMdClose /> : <LuMenu />}
        </div>

        <div
          className={classNames.mobileMenu}
          style={{
            height: isNavbarFixed ? "calc(100vh - 80px)" : "calc(100vh - 90px)",
            display: hamburgerMenu ? "" : "none",
          }}
        >
          <div className={classNames.menuItems}>
            <div>
              About us <IoIosArrowDown />
            </div>
            <div>
              Solutions <IoIosArrowDown />
            </div>
            <div>
              Services <IoIosArrowDown />
            </div>
            <div>
              Resources <IoIosArrowDown />
            </div>
          </div>
          <div className={classNames.btnsContainer}>
            <div onClick={() => navigate.push("/login")}>Log in</div>
            <div onClick={() => navigate.push("/waitinglist")}>
              Join WaitList
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
