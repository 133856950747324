import Cookies from "js-cookie";
import React, { createContext, useState } from "react";
import { APP_USER_TOKEN } from "../assets/data/constant";

export const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
  const [loginStep, setLoginStep] = useState(1);
  const [globalLoading, setGlobalLoading] = useState(false);

  //credentials
  const [email, setEmail] = useState(
    localStorage.getItem("nvestBankLoginAccount") || ""
  );
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("nvestBankAccessToken") || ""
  );
  const [idToken, setIdToken] = useState(
    localStorage.getItem("nvestBankIdToken") || ""
  );

  const [selectedLeftDrawMenu, setSelectedLeftDrawMenu] = useState(null);

  const userLoginHandler = (paramEmail, paramAccessToken, paramIdToken) => {
    if (paramEmail && paramAccessToken && paramIdToken) {
      localStorage.setItem("nvestBankLoginAccount", paramEmail);
      localStorage.setItem("nvestBankAccessToken", paramAccessToken);
      localStorage.setItem("nvestBankIdToken", paramIdToken);
      setEmail(paramEmail);
      setAccessToken(paramAccessToken);
      setIdToken(paramIdToken);
    }
    if (!paramEmail || !paramAccessToken || !paramIdToken) {
      Cookies.remove(APP_USER_TOKEN);
    }
  };

  // CRM
  const [overlayDeleteItemName, setOverlayDeleteItemName] = useState("");
  const [actionBtns, setActionBtns] = useState([]);

  return (
    <GlobalContext.Provider
      value={{
        loginStep,
        setLoginStep,
        userLoginHandler,
        globalLoading,
        setGlobalLoading,
        //credentials
        email,
        setEmail,
        accessToken,
        setAccessToken,
        idToken,
        setIdToken,
        //CRM
        overlayDeleteItemName,
        setOverlayDeleteItemName,
        actionBtns,
        setActionBtns,
        selectedLeftDrawMenu,
        setSelectedLeftDrawMenu,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
