import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./login.module.scss";

//assets
import fullLogo from "../../assets/images/logo.svg";
import miniLogo from "../../assets/images/minilogo.svg";
import { mainMenuItems } from "../../assets/data/mapItems";
import bookkeepingIcon from "../../assets/images/menu/bookkeeping/bookkeeping.svg";
import googleIcon from "../../assets/images/google.svg";

//functionalities
import {
  loginFunc,
  isEmailRegisteredInFinaceFunc,
} from "../../assets/data/api";
import Cookies from "js-cookie";
import { Link, useHistory } from "react-router-dom";
import { GlobalContext } from "../../context/Globalcontext";
import { useLoadAppDetails } from "../../queryHooks";
import { APP_CODE, APP_USER_TOKEN } from "../../assets/data/constant";
import { useMutation } from "react-query";

const Login = () => {
  const { loginStep, setLoginStep, userLoginHandler } =
    useContext(GlobalContext);
  const navigate = useHistory();

  //page values
  const [emailCredentials, setEmailCredentials] = useState("");
  const [passwordCredentials, setPasswordCredentials] = useState("");
  const [menuSelected, setMenuSelected] = useState("");

  //login functions
  const { appByCode: appLoginByCode, appByCodeLoading: loginByCodeLoading } =
    useLoadAppDetails(APP_CODE);

  const handleLoginSuccess = async (data) => {
    console.log(data);
    const finaceUserData = await isEmailRegisteredInFinaceFunc(
      data?.user?.email
    );
    localStorage.clear();
    localStorage.setItem("accessToken appSpecific", data?.accessToken);
    await userLoginHandler(emailCredentials, data.accessToken, data.idToken);
    Cookies.set(APP_USER_TOKEN, data.idToken);
    navigate.push("/dashboard");
    // if (finaceUserData?.data?.email) {
    //   localStorage.clear();
    //   localStorage.setItem("accessToken appSpecific", data?.accessToken);
    //   await userLoginHandler(emailCredentials, data.accessToken, data.idToken);
    //   Cookies.set(APP_USER_TOKEN, data.idToken);
    //   navigate.push("/dashboard");
    // } else {
    //   navigate.push("/createFinace");
    // }
  };

  const {
    isLoading: isLoggingIn,
    mutate: attemptLogin,
    data: loginAttemptResponse,
  } = useMutation(loginFunc, {
    onSuccess: (data) => {
      if (data.status) {
        handleLoginSuccess(data);
      } else {
        setLoginStep(99);
      }
    },
  });

  const dropdownMenuRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(event.target)
      ) {
        setMenuSelected("");
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={classNames.loginPage}>
      {/* <div className={classNames.topNav} ref={dropdownMenuRef}>
        <img
          src={bookkeepingIcon}
          alt="bookkeepingIcon"
          onClick={() => setMenuSelected((prev) => !prev)}
        />
        {menuSelected && (
          <div className={classNames.menuDropdown}>
            <div className={classNames.mainMenu}>
              {mainMenuItems?.map((eachItem, index) => {
                return (
                  <div
                    className={classNames.eachItem}
                    key={eachItem?.name + index}
                  >
                    <div className={classNames.imageDiv}>
                      <img src={eachItem?.image} alt={eachItem?.name} />
                    </div>
                    <div>{eachItem?.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div> */}
      <div className={classNames.loginCard}>
        <div className={classNames.leftCard}>
          <img
            src={fullLogo}
            alt="fullLogo"
            onClick={() => navigate.push("/")}
          />
          {loginStep === 1 ? (
            <div className={classNames.credentialsContainer}>
              <div className={classNames.title}>Sign In</div>
              <input
                className={classNames.inputContainer}
                placeholder="Email"
                type="email"
                value={emailCredentials}
                onChange={(event) => {
                  setEmailCredentials(event?.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    setLoginStep(2);
                  }
                }}
              />
              <button
                className={classNames.submitBtn}
                onClick={() => {
                  setLoginStep(2);
                }}
              >
                Next
              </button>
              <div className={classNames.normalBtn}>Forgot Password?</div>
              <div className={classNames.seperator}>
                <span>OR</span>
              </div>
              <div className={classNames.transparentBtn}>
                <img src={googleIcon} alt="googleIcon" />
                Continue With Google
              </div>
            </div>
          ) : loginStep === 2 || loginStep === 99 ? (
            <div className={classNames.credentialsContainer}>
              <div className={classNames.title}>Sign In</div>
              <input
                className={classNames.inputContainer}
                placeholder="Email"
                value={emailCredentials}
                onChange={(event) => {
                  setEmailCredentials(event?.target.value);
                }}
              />
              <input
                className={classNames.inputContainer}
                placeholder="Password"
                type="password"
                value={passwordCredentials}
                onChange={(event) => {
                  setPasswordCredentials(event?.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    if (appLoginByCode) {
                      attemptLogin({
                        email: emailCredentials,
                        password: passwordCredentials,
                      });
                    }
                  }
                }}
              />
              {loginStep === 99 && (
                <div className={classNames.loginMessage}>
                  Enter valid credentials!
                </div>
              )}
              <button
                className={classNames.submitBtn}
                style={{
                  opacity: isLoggingIn ? "0.5" : "",
                  pointerEvents: isLoggingIn ? "none" : "",
                }}
                onClick={() => {
                  if (appLoginByCode) {
                    attemptLogin({
                      email: emailCredentials,
                      password: passwordCredentials,
                    });
                  }
                }}
              >
                {isLoggingIn ? "Loading..." : "Sign In"}
              </button>
              <div className={classNames.normalBtn}>Forgot Password?</div>
              <div className={classNames.seperator}>
                <span>OR</span>
              </div>
              <div className={classNames.transparentBtn}>
                <img src={googleIcon} alt="googleIcon" />
                Continue With Google
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className={classNames.rightCard}>
          <img src={miniLogo} alt="miniLogo" />
        </div>
        <div className={classNames.bottomMessage}>
          Don&apos;t Have A Finace Account?{" "}
          <Link to="/registration">Sign Up Now</Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
