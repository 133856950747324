import React from "react";
import classNames from "./philosophy.module.scss";
import codeImage from "../../../assets/images/pages/home/landing/image/code.png";
const Philosophy = () => {
  return (
    <section className={classNames.workflowPage} id="services">
      <div className={classNames.workflow}>
        <div className={classNames.platformTitle}>#Philosophy</div>
        <div className={classNames.title}>
          <div>The Philosophy of FinACE: Transforming</div>
          <div>the Role of Accountants</div>
        </div>
        <div className={classNames.desc}>
          At FinACE, we believe in not just keeping pace with the evolving world
          of accounting and tax services, but in shaping its future. Our
          philosophy centers on transforming the traditional role of
          accountants, empowering them with cutting-edge technology, and
          redefining their contribution to the business landscape. Here's a look
          at our vision and the journey we envision for accountants:
        </div>
        <div className={classNames.accountantsSection}>
          <div className={classNames.textSection}>
            <div className={classNames.titleHead}>
              <p className={classNames.firstHtag}>What Accountants Were -</p>
              <h2>Desk Executives</h2>
            </div>
            <div className={classNames.titlePtag}>
              <p>
                In the past, accountants were primarily desk executives,
                meticulously handling manual bookkeeping, data entry, and
                financial record-keeping. Their roles were often confined to
                managing paperwork and ensuring compliance with financial
                regulations. This manual approach, while effective, was
                time-consuming and prone to human error.
              </p>
            </div>
          </div>
          <div className={classNames.imageSection}>
            <img src={codeImage} alt="Accountants" />
          </div>
        </div>
        <div className={classNames.bottomDiv}>
          <div className={classNames.textSectionBottom}>
            <div className={classNames.titleHeadBottom}>
              <p className={classNames.firstHtagBottom}>
                What Accountants Are Now -
              </p>
              <h2>DAvid Users of Information Systems</h2>
            </div>
            <div className={classNames.titlePtagBottom}>
              <p>
                Today, the landscape has shifted significantly. Accountants have
                become avid users of advanced information systems and software
                solutions. With the advent of digital tools, they are now able
                to streamline processes, enhance accuracy, and provide more
                strategic insights to their clients. However, the reliance on
                these systems has also brought new challenges, including the
                need for continuous learning and adaptation to emerging
                technologies.
              </p>
            </div>
          </div>
          <div className={classNames.textSectionBottom}>
            <div className={classNames.titleHeadBottom}>
              <p className={classNames.firstHtagBottom}>
                What FinACE Wants Accountants to Be -
              </p>
              <h2>AI Trainers that Automate the Accounting Process</h2>
            </div>
            <div className={classNames.titlePtagBottom}>
              <p>
                At FinACE, we envision a future where accountants are not just
                users of technology but are at the forefront of driving
                technological advancements. We want accountants to evolve into
                AI Trainers, leveraging artificial intelligence to automate the
                accounting process and significantly enhance efficiency and
                accuracy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Philosophy;
