import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./layout.module.scss";
import { GlobalContext } from "../context/Globalcontext";
import { Redirect, useHistory } from "react-router-dom";

//assets
import logoWithName from "../assets/images/logowithname.svg";
import lockIcon from "../assets/images/sidebar/icons/lock.svg";
import overviewIcon from "../assets/images/sidebar/logo/overview.svg";
import booksIcon from "../assets/images/sidebar/logo/books.svg";
import lucaIcon from "../assets/images/sidebar/logo/luca.svg";
import usersIcon from "../assets/images/sidebar/logo/users.svg";
import financeIcon from "../assets/images/sidebar/logo/finance.svg";
import walletscanIcon from "../assets/images/sidebar/logo/walletscan.svg";

//menu icons
import bookkeepingIcon from "../assets/images/menu/bookkeeping/bookkeeping.svg";
import notificationIcon from "../assets/images/icons/notification.svg";
import { BsDot } from "react-icons/bs";
import { MdKeyboardArrowUp } from "react-icons/md";
import tempNotoficationImage from "../assets/images/pages/dashboard/tempnotificationimage.svg";
import headspaceIcon from "../assets/images/pages/dashboard/headspace.svg";
import rahaIcon from "../assets/images/pages/dashboard/raha.svg";

import {
  mainMenuItems,
  subMenuItemsAccountants,
  subMenuItemsAdmin,
  subMenuItemsCPAAdmin,
  subMenuItemsCPAEmployees,
  subMenuItemsClients,
} from "../assets/data/mapItems";
import { FaChevronRight } from "react-icons/fa";

const PostLogin = ({ child }) => {
  const navigate = useHistory();
  const {
    email,
    userLoginHandler,
    selectedLeftDrawMenu,
    setSelectedLeftDrawMenu,
  } = useContext(GlobalContext);

  const [selectedSidebarItem, setSelectedSidebarItem] = useState("");
  const [dropdownActive, setDropdownActive] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    localStorage.getItem("selectedMenuItem") || "Clients"
  );
  const [selectedMenuItemSub, setSelectedMenuItemSub] = useState(
    localStorage.getItem("selectedMenuItemSub") || "Fin(AI)ssured"
  );
  const [selectedMenuItemHover, setSelectedMenuItemHover] = useState("");
  const [selectedMenuItemSubHover, setSelectedMenuItemSubHover] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [showSideDraw, setShowSideDraw] = useState(false);
  const sideDrawItems = [
    {
      title: "Dashboard",
      link: "/cpaadmin/finace/dashboard",
    },
    {
      title: "Inputs",
      link: "/cpaadmin/finace/input",
    },
    {
      title: "Outputs",
      link: "/cpaadmin/finace/output",
    },
    {
      title: "Task Manager",
      link: "/cpaadmin/finace/taskmanager",
    },
  ];

  function logoutFunction() {
    userLoginHandler();
    localStorage.clear();
    navigate.push(`/`);
  }

  const dropdownMenuButtonRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const notificationRef = useRef(null);
  const notificationButtonRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(event.target) &&
        dropdownMenuButtonRef.current &&
        !dropdownMenuButtonRef.current.contains(event.target)
      ) {
        setDropdownActive(false);
      }
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target) &&
        notificationButtonRef.current &&
        !notificationButtonRef.current.contains(event.target)
      ) {
        setOpenNotification(false);
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (window?.location?.pathname === "/cpaadmin/finace/dashboard") {
      setSelectedLeftDrawMenu({
        path: "/cpaadmin/finace/dashboard",
        title: "Dashboard",
      });
    } else if (window?.location?.pathname === "/cpaadmin/finace/taskmanager") {
      setSelectedLeftDrawMenu({
        path: "/cpaadmin/finace/taskmanager",
        title: "Task Manager",
      });
    }
  }, [window?.location?.pathname]);

  function renderSidebar() {
    switch (window?.location?.pathname) {
      case "/admin/crm/users":
        return (
          <>
            <div
              className={`${classNames.eachItem} ${
                (selectedSidebarItem === "Users" ||
                  selectedSidebarItem === "") &&
                classNames.eachItemSelected
              }`}
              onClick={() => setSelectedSidebarItem("Users")}
            >
              <img src={usersIcon} alt="usersIcon" />
              <div className={classNames.title}>Users</div>
            </div>
          </>
        );
      case "/admin/crm/monitoring":
        return (
          <>
            <div
              className={`${classNames.eachItem} ${
                (selectedSidebarItem === "Users" ||
                  selectedSidebarItem === "") &&
                classNames.eachItemSelected
              }`}
              onClick={() => setSelectedSidebarItem("Users")}
            >
              <img src={usersIcon} alt="usersIcon" />
              <div className={classNames.title}>Users</div>
            </div>
          </>
        );
      case "/dashboard":
        return (
          <>
            <div
              className={`${classNames.eachItem} ${
                (selectedSidebarItem === "Overview" ||
                  selectedSidebarItem === "") &&
                classNames.eachItemSelected
              }`}
              onClick={() => setSelectedSidebarItem("Overview")}
            >
              <img src={overviewIcon} alt="overviewIcon" />
              <div className={classNames.title}>Overview</div>
            </div>
            <div
              className={`${classNames.eachItem} ${
                selectedSidebarItem === "Books" && classNames.eachItemSelected
              }`}
              onClick={() => setSelectedSidebarItem("Books")}
            >
              <img src={booksIcon} alt="booksIcon" />
              <div className={classNames.title}>Books</div>
            </div>
            <div
              className={`${classNames.eachItem} ${
                selectedSidebarItem === "Luca" && classNames.eachItemSelected
              }`}
              onClick={() => setSelectedSidebarItem("Luca")}
            >
              <img src={lucaIcon} alt="lucaIcon" />
              <div className={classNames.title}>Luca</div>
            </div>
          </>
        );
      case "/clients/finaceured/walletscan":
        return (
          <>
            <div
              className={`${classNames.eachItem} ${
                (selectedSidebarItem === "WalletScan" ||
                  selectedSidebarItem === "") &&
                classNames.eachItemSelected
              }`}
              onClick={() => setSelectedSidebarItem("WalletScan")}
            >
              <img src={walletscanIcon} alt="walletscanIcon" />
              <div className={classNames.title}>WalletScan</div>
            </div>
          </>
        );
      // CPA Admin Routes
      case "/cpaadmin/finace/dashboard":
        return (
          <>
            <div
              className={`${classNames.eachItem} ${
                (selectedSidebarItem === "Fin(AI)ssured" ||
                  selectedSidebarItem === "") &&
                classNames.eachItemSelected1
              }`}
              onClick={() => setSelectedSidebarItem("Fin(AI)ssured")}
            >
              <img src={financeIcon} alt="usersIcon" />
              <div className={classNames.title}>Fin(AI)ssured</div>
            </div>
          </>
        );
      case "/cpaadmin/finace/taskmanager":
        return (
          <>
            <div
              className={`${classNames.eachItem} ${
                (selectedSidebarItem === "Fin(AI)ssured" ||
                  selectedSidebarItem === "") &&
                classNames.eachItemSelected1
              }`}
              onClick={() => setSelectedSidebarItem("Fin(AI)ssured")}
            >
              <img src={financeIcon} alt="usersIcon" />
              <div className={classNames.title}>Fin(AI)ssured</div>
            </div>
          </>
        );
      case "/cpaadmin/finace/input":
        return (
          <>
            <div
              className={`${classNames.eachItem} ${
                (selectedSidebarItem === "Fin(AI)ssured" ||
                  selectedSidebarItem === "") &&
                classNames.eachItemSelected1
              }`}
              onClick={() => setSelectedSidebarItem("Fin(AI)ssured")}
            >
              <img src={financeIcon} alt="usersIcon" />
              <div className={classNames.title}>Fin(AI)ssured</div>
            </div>
          </>
        );
      case "/cpaadmin/finace/output":
        return (
          <>
            <div
              className={`${classNames.eachItem} ${
                (selectedSidebarItem === "Fin(AI)ssured" ||
                  selectedSidebarItem === "") &&
                classNames.eachItemSelected1
              }`}
              onClick={() => setSelectedSidebarItem("Fin(AI)ssured")}
            >
              <img src={financeIcon} alt="usersIcon" />
              <div className={classNames.title}>Fin(AI)ssured</div>
            </div>
          </>
        );

      default:
        break;
    }
  }

  if (!email) {
    return <Redirect to="/login" />;
  }

  return (
    <div className={classNames.postLogin}>
      <div className={classNames.navbar}>
        <img
          src={logoWithName}
          alt="logoWithName"
          onClick={() => navigate.push("/dashboard")}
        />
        <input
          className={classNames.inputContainer}
          placeholder="Search Finace"
          type="text"
        />
        <div
          className={classNames.menuBar}
          // onMouseLeave={() => {
          //   setDropdownActive(false);
          // }}
        >
          <div className={classNames.menuBtn} ref={dropdownMenuButtonRef}>
            <div
              onClick={() => {
                setDropdownActive(true);
                setSelectedMenuItemHover(selectedMenuItem);
                setSelectedMenuItemSubHover(selectedMenuItemSub);
              }}
            >
              {selectedMenuItem}
            </div>
            <div
              style={{
                display: selectedMenuItemSub ? "" : "none",
                borderRight: "0.5px solid #e5e5e5",
              }}
              onClick={() => {
                setDropdownActive(true);
                setSelectedMenuItemHover(selectedMenuItem);
                setSelectedMenuItemSubHover(selectedMenuItemSub);
              }}
            >
              {selectedMenuItemSub}
            </div>
            <div
              className={classNames.profileDiv}
              onClick={() => setOpenNotification(true)}
              ref={notificationButtonRef}
            >
              <img
                src={notificationIcon}
                alt="notificationIcon"
                className={classNames.notificationIcon}
              />
              {openNotification && (
                <div
                  className={classNames.dropdownContent}
                  ref={notificationRef}
                >
                  <div className={classNames.singleNotification}>
                    <div className={classNames.heading}>
                      <div className={classNames.title}>
                        <img src={headspaceIcon} alt="headspaceIcon" />
                        Headspace
                      </div>
                      <div>
                        <BsDot />
                      </div>
                      <div className={classNames.duration}>
                        Now <MdKeyboardArrowUp />
                      </div>
                      <div className={classNames.closeBtn}>X</div>
                    </div>
                    <div className={classNames.notificationTitle}>
                      New Guide to Meditation Video Available!
                    </div>
                    <div className={classNames.notificationMessage}>
                      Explore our latest meditation video guide on Headspace.
                      Whether you are a seasoned meditator or just starting out,
                      this video offers valuable insights and techniques to help
                      you find inner peace and mindfulness. Check it out now and
                      enhance your meditation journey with Headspace!
                    </div>
                    <img
                      src={tempNotoficationImage}
                      alt=""
                      className={classNames.notificationImage}
                    />
                    <div className={classNames.notificationBtn}>
                      Share with Employees
                    </div>
                  </div>
                  <div className={classNames.singleNotification}>
                    <div className={classNames.heading}>
                      <div className={classNames.title}>
                        <img src={rahaIcon} alt="rahaIcon" />
                        Raha
                      </div>
                      <div>
                        <BsDot />
                      </div>
                      <div className={classNames.duration}>
                        2 Days Ago <MdKeyboardArrowUp />
                      </div>
                      <div className={classNames.closeBtn}>X</div>
                    </div>
                    <div className={classNames.notificationTitle}>
                      New Endorsement Created
                    </div>
                    <div className={classNames.notificationMessage}>
                      You have successfully created endorsement on 02/09/2024
                      for 10 new Employees joining the company and 4 employees
                      leaving.
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {dropdownActive && (
            <div className={classNames.menuDropdown} ref={dropdownMenuRef}>
              <div className={classNames.mainMenu}>
                {mainMenuItems?.map((eachItem, index) => {
                  return (
                    <div
                      className={`${classNames.eachItem} ${
                        // selectedMenuItem === eachItem?.name ||
                        selectedMenuItemHover === eachItem?.name &&
                        classNames.eachItemSelected
                      }`}
                      key={eachItem?.name + index}
                      onClick={() => {
                        setSelectedMenuItem(eachItem?.name);
                        setSelectedMenuItemSub(eachItem?.subMenuItem);
                        localStorage.setItem(
                          "selectedMenuItem",
                          eachItem?.name
                        );
                        localStorage.setItem(
                          "selectedMenuItemSub",
                          eachItem?.subMenuItem
                        );
                      }}
                      onMouseEnter={() => {
                        setSelectedMenuItemHover(eachItem?.name);
                        setSelectedMenuItemSubHover(selectedMenuItemSub);
                      }}
                    >
                      {/* <div className={classNames.imageDiv}>
                        <img src={eachItem?.image} alt={eachItem?.name} />
                      </div> */}
                      <div>{eachItem?.name}</div>
                    </div>
                  );
                })}
              </div>
              <div className={classNames.subMenu}>
                {selectedMenuItemHover === "Clients" ? (
                  <>
                    {subMenuItemsClients?.map((eachItem, index) => {
                      return (
                        <div
                          key={eachItem?.name + index}
                          onClick={() => {
                            setSelectedMenuItem("Clients");
                            setSelectedMenuItemSub(eachItem?.name);
                            localStorage.setItem(
                              "selectedMenuItemSub",
                              eachItem?.name
                            );
                            if (eachItem?.link) {
                              setDropdownActive(false);
                              navigate.push(eachItem?.link);
                            }
                          }}
                          onMouseEnter={() => {
                            setSelectedMenuItemSubHover(eachItem?.name);
                          }}
                          className={
                            selectedMenuItemSubHover === eachItem?.name
                              ? classNames.subHeading
                              : ""
                          }
                        >
                          {eachItem?.name}
                        </div>
                      );
                    })}
                  </>
                ) : selectedMenuItemHover === "CPA Admin" ? (
                  <>
                    {subMenuItemsCPAAdmin?.map((eachItem, index) => {
                      return (
                        <div
                          key={eachItem?.name + index}
                          onClick={() => {
                            setSelectedMenuItem("CPA Admin");
                            setSelectedMenuItemSub(eachItem?.name);
                            localStorage.setItem(
                              "selectedMenuItemSub",
                              eachItem?.name
                            );
                            if (eachItem?.link) {
                              setDropdownActive(false);
                              navigate.push(eachItem?.link);
                            }
                          }}
                          onMouseEnter={() => {
                            setSelectedMenuItemSubHover(eachItem?.name);
                          }}
                          className={
                            selectedMenuItemSubHover === eachItem?.name
                              ? classNames.subHeading
                              : ""
                          }
                        >
                          {eachItem?.name}
                        </div>
                      );
                    })}
                  </>
                ) : selectedMenuItemHover === "CPA Employees" ? (
                  <>
                    {subMenuItemsCPAEmployees?.map((eachItem, index) => {
                      return (
                        <div
                          key={eachItem?.name + index}
                          onClick={() => {
                            setSelectedMenuItem("CPA Employees");
                            setSelectedMenuItemSub(eachItem?.name);
                            localStorage.setItem(
                              "selectedMenuItemSub",
                              eachItem?.name
                            );
                            if (eachItem?.link) {
                              setDropdownActive(false);
                              navigate.push(eachItem?.link);
                            }
                          }}
                          onMouseEnter={() => {
                            setSelectedMenuItemSubHover(eachItem?.name);
                          }}
                          className={
                            selectedMenuItemSubHover === eachItem?.name
                              ? classNames.subHeading
                              : ""
                          }
                        >
                          {eachItem?.name}
                        </div>
                      );
                    })}
                  </>
                ) : selectedMenuItemHover === "Accountants" ? (
                  <>
                    {subMenuItemsAccountants?.map((eachItem, index) => {
                      return (
                        <div
                          key={eachItem?.name + index}
                          onClick={() => {
                            setSelectedMenuItem("Accountants");
                            setSelectedMenuItemSub(eachItem?.name);
                            localStorage.setItem(
                              "selectedMenuItemSub",
                              eachItem?.name
                            );
                            if (eachItem?.link) {
                              setDropdownActive(false);
                              navigate.push(eachItem?.link);
                            }
                          }}
                          onMouseEnter={() => {
                            setSelectedMenuItemSubHover(eachItem?.name);
                          }}
                          className={
                            selectedMenuItemSubHover === eachItem?.name
                              ? classNames.subHeading
                              : ""
                          }
                        >
                          {eachItem?.name}
                        </div>
                      );
                    })}
                  </>
                ) : selectedMenuItemHover === "Admin" ? (
                  <>
                    {subMenuItemsAdmin?.map((eachItem, index) => {
                      return (
                        <div
                          key={eachItem?.name + index}
                          onClick={() => {
                            setSelectedMenuItem("Admin");
                            setSelectedMenuItemSub(eachItem?.name);
                            localStorage.setItem(
                              "selectedMenuItemSub",
                              eachItem?.name
                            );
                            if (eachItem?.link) {
                              setDropdownActive(false);
                              navigate.push(eachItem?.link);
                            }
                          }}
                          onMouseEnter={() => {
                            setSelectedMenuItemSubHover(eachItem?.name);
                          }}
                          className={
                            selectedMenuItemSubHover === eachItem?.name
                              ? classNames.subHeading
                              : ""
                          }
                        >
                          {eachItem?.name}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={classNames.layoutContainer}>
        <div
          className={classNames.circleCollapse}
          onClick={(e) => setShowSideDraw(!showSideDraw)}
        >
          <FaChevronRight style={{ fontSize: "10px" }} />
        </div>
        <div className={classNames.sidebar}>
          <div className={classNames.sidebarMenu}>{renderSidebar()}</div>
          <div className={classNames.settingsMenu}>
            <div className={classNames.eachItem} onClick={logoutFunction}>
              <img src={lockIcon} alt="lockIcon" />
            </div>
          </div>
        </div>
        {showSideDraw && (
          <div className={classNames.sideDraw}>
            {sideDrawItems.map((item) => (
              <div
                onClick={(e) => {
                  setSelectedLeftDrawMenu({
                    path: window?.location?.pathname,
                    title: item.title,
                  });
                  navigate.push(item.link);
                  setShowSideDraw(false);
                }}
                className={
                  selectedLeftDrawMenu?.path === window?.location?.pathname &&
                  selectedLeftDrawMenu.title === item.title
                    ? classNames.selectedItem
                    : ""
                }
              >
                {item.title}
              </div>
            ))}
          </div>
        )}
        <div className={classNames.childContainer}>{child}</div>
      </div>
    </div>
  );
};

export default PostLogin;
