// src/components/CTOList.js
import React from 'react';
import CTOCard from './CTOCard';
import classNames from './ctolist.module.scss';
import cto1 from '../../../assets/images/pages/home/landing/image/cto86.png'
import cto2 from '../../../assets/images/pages/home/landing/image/cto87.png'
import cto3 from '../../../assets/images/pages/home/landing/image/cto88.png'
import cto4 from '../../../assets/images/pages/home/landing/image/cto89.png'
import cto5 from '../../../assets/images/pages/home/landing/image/cto90.png'
import cto6 from '../../../assets/images/pages/home/landing/image/cto91.png'
const CTOList = () => {
  const ctos = [
    { name: 'Vinod Ravi M.com', image: cto1 , title:"Chief Operations Officer - Services" },
    { name: 'Pavan VKR B.com', image: cto2 , title:"Chief Relationship Officer" },
    { name: 'Dipthi Bobba Global MBA', image: cto3 , title:"Chief Delivery Officer" },
    { name: 'Tarun Challa CPA(US),CPA(Canada)', image: cto4 , title:"Chief Finance Officer" },
    { name: 'PaVan Balusu M.com', image: cto5 , title:"Chief Marketing Officer" },
    { name: 'Shorupan Pirakaspathy', image: cto6 , title:"Chief Technology Officer"}
  ];

  return (
    <div className={classNames.ctoList}>
      {ctos.map((cto, index) => (
        <div key={index} className={classNames.ctoListItem}>
          <CTOCard name={cto.name} image={cto.image} title={cto.title} />
        </div>
      ))}
    </div>
  );
};

export default CTOList;
