import React, { useState } from "react";
import classNames from "./output.module.scss";
import { IoMdArrowDropdown } from "react-icons/io";
import Luca from "../../../assets/images/sidebar/logo/luca.svg";
import Intuit from "./assets/intuit.svg";

const CpaOutput = () => {
  const [showTxn, setShowTxn] = useState(false);
  return (
    <div className={classNames.mainSection}>
      <h4>Selected client</h4>
      <div className={classNames.clientUser}>
        <div className={classNames.clientPhoto}>
          <img src={Luca} alt="client_photo" />
        </div>
        <div>
          <p className={classNames.clientName}>Alan Smith</p>
          <p className={classNames.clientEmail}>alansmith@gmail.com</p>
        </div>
        <IoMdArrowDropdown />
      </div>
      <h4 style={{ marginBottom: "20px" }}>Today</h4>
      <div className={classNames.quickbookTxn}>
        <div
          className={classNames.quickbookTxnTop}
          onClick={() => setShowTxn(!showTxn)}
        >
          <div className={classNames.eachTxnInfo}>
            <div className={classNames.eachTxnImg}>
              <img src={Intuit} alt="book_logo" />
            </div>
            <div className={classNames.eachTxnSource}>
              <h4>Intuit Quickbooks</h4>
              <p>Input source</p>
            </div>
          </div>
          <div className={classNames.eachTxnInfo}>
            <div className={classNames.eachTxnImg}>
              <img src={Intuit} alt="book_logo" />
            </div>
            <div className={classNames.eachTxnSource}>
              <h4>Intuit Quickbooks</h4>
              <p>Output source</p>
            </div>
          </div>
          <div className={classNames.eachTxnRecord}>
            <h4>15 Txn’s Recorded</h4>
            <p>5:56 PM EST</p>
          </div>
          <button className={classNames.statusBtnTxn}>In Progress</button>
          <IoMdArrowDropdown style={{ fontSize: "24px" }} />
        </div>
        {showTxn && (
          <div className={classNames.quickTxnDetail}>
            <h4 style={{ marginBottom: "20px" }}>Initiated By</h4>
            <div className={classNames.initiatedBy}>Client</div>
            <div className={classNames.dataStruct}>
              <div className={classNames.dataStructLeft}>
                <h4 style={{ marginBottom: "20px" }}>Raw Data Into Ai</h4>
              </div>
              <div className={classNames.dataStructRight}>
                <h4 style={{ marginBottom: "20px" }}>Structured Data Out</h4>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CpaOutput;
