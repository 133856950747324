import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./monitoring.module.scss";
import SideMenu from "../Common/sideMenu";
import CrmTopbar from "../Common/crmTopbar";
import { CopyBtn } from "../../../components/Custom";
import { ReactComponent as SortArrows } from "../../../assets/images/icons/sort.svg";
import Skeleton from "react-loading-skeleton";
import { GlobalContext } from "../../../context/Globalcontext";
import { useNetworkRequest } from "../../../hooks/networkRequest";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoMdArrowDropright } from "react-icons/io";

const Monitoring = () => {
  const { actionBtns, setActionBtns } = useContext(GlobalContext);
  const { allValues: allLeads, allValuesLoading: allLeadsLoading } =
    useNetworkRequest({
      URL: "https://comms.globalxchange.io/gxb/apps/users/get?app_code=finace",
    });

  //page values
  const [filterOpen, setFilterOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [allBrokeragesSearch, setAllBrokeragesSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [expandDetails, setExpandDetails] = useState([]);
  const [dotMenus, setDotMenus] = useState([
    { id: 1, mainName: "Change session status" },
    { id: 2, mainName: "See all user’s sessions" },
    { id: 3, mainName: "See all user’s transactions" },
  ]);
  const [selectedDetails, setSelectedDetails] = useState("expandSessionInfo");

  const filterDropdownRef = useRef(null);
  const selectedItemTable = useRef(null);

  const filterItems = [];

  function expandSessionInfoFunc(actionID) {
    setExpandDetails([actionID]);
    setSelectedDetails("expandSessionInfo");
  }

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setFilterOpen(false);
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={classNames.sidemenuNcrm}>
      <SideMenu />
      <CrmTopbar />

      <div className={classNames.crmUsers}>
        <div className={classNames.allLeads}>
          <div
            className={classNames.tableContainer}
            style={{ border: allLeads ? "" : "none" }}
          >
            <table style={{ minWidth: "100%" }}>
              <thead>
                <tr
                  style={{ gridTemplateColumns: "min-content repeat(5, 1fr)" }}
                >
                  <th></th>
                  <th>
                    Name <SortArrows />
                  </th>
                  <th>
                    Application <SortArrows />
                  </th>
                  <th>
                    Date <SortArrows />
                  </th>
                  <th>
                    Time <SortArrows />
                  </th>
                  <th>
                    Session Status <SortArrows />
                  </th>
                </tr>
              </thead>
              <tbody>
                {allLeadsLoading ? (
                  <tr
                    key={"loading"}
                    style={{
                      gridTemplateColumns: "min-content repeat(5, 1fr)",
                    }}
                  >
                    <td>
                      <BsThreeDotsVertical />
                    </td>
                    <td>
                      <Skeleton width={100} height={20} />
                    </td>
                    <td>
                      <Skeleton width={100} height={20} />
                    </td>
                    <td>
                      <Skeleton width={100} height={20} />
                    </td>
                    <td>
                      <Skeleton width={100} height={20} />
                    </td>
                    <td>
                      <Skeleton width={100} height={20} />
                    </td>
                  </tr>
                ) : (
                  allLeads?.users?.length > 0 &&
                  allLeads?.users
                    ?.filter((row) => {
                      // Convert the row object values to an array and check if any value matches the search query
                      const values = Object?.values(row);
                      const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                      return values?.some((value) => {
                        if (typeof value === "string") {
                          return value?.toLowerCase()?.includes(searchQuery);
                        }
                        return false;
                      });
                    })
                    ?.sort((a, b) =>
                      a?.first_name?.localeCompare(b?.first_name)
                    )
                    ?.map((row, index) => (
                      <>
                        <tr
                          key={row?.brokerage_name + index}
                          style={{
                            gridTemplateColumns: "min-content repeat(5, 1fr)",
                            pointerEvents: sidebarOpen ? "none" : "",
                          }}
                        >
                          <td>
                            {actionBtns?.includes(row?._id) ? (
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  //   if (expandDetails?.includes(row?._id)) {
                                  //     setExpandDetails([]);
                                  //   }
                                  setActionBtns([]);
                                }}
                              >
                                X
                              </span>
                            ) : (
                              <BsThreeDotsVertical
                                onClick={() => {
                                  if (actionBtns?.includes(row?._id)) {
                                    setActionBtns([]);
                                  } else {
                                    setActionBtns([row?._id]);
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            )}
                            {actionBtns?.includes(row?._id) ? (
                              <div
                                className={classNames.dropdownContent}
                                ref={selectedItemTable}
                              >
                                {dotMenus.map((menuInfo, i) => {
                                  return (
                                    <div
                                      key={menuInfo.id + i}
                                      className={classNames.eachFilterItem}
                                      onClick={() =>
                                        expandSessionInfoFunc(row?._id)
                                      }
                                    >
                                      {menuInfo.mainName}
                                    </div>
                                  );
                                })}
                              </div>
                            ) : null}
                          </td>
                          <td>
                            {row?.name ? row?.name : ""}
                            {row?.name ? (
                              <CopyBtn copyText={row?.name ? row?.name : ""} />
                            ) : (
                              ""
                            )}
                          </td>
                          <td>{""}</td>
                          <td>
                            {""}
                            <CopyBtn copyText={""} />
                          </td>
                          <td>
                            {""}
                            <CopyBtn copyText={""} />
                          </td>
                          <td>
                            <span className={classNames.activeStatus}>
                              Active
                            </span>
                          </td>
                        </tr>
                        {selectedDetails === "expandSessionInfo" ? (
                          <tr
                            style={{
                              gridTemplateColumns: "unset",
                            }}
                          >
                            <div className={classNames.expandInfo}>
                              <p className={classNames.sessionDay}>Today</p>
                              <div className={classNames.eachSession}>
                                <p>ID</p>
                                <p>Jan 2nd 2023 at 5:34 PM EST</p>
                                <IoMdArrowDropright />
                                <p>Jan 2nd 2023 at 5:34 PM EST</p>
                              </div>
                            </div>
                          </tr>
                        ) : (
                          ""
                        )}
                      </>
                    ))
                )}
              </tbody>
            </table>
          </div>

          <div className={classNames.paginationBar}>
            <button>Previous</button>
            <div className={classNames.allPagination}>
              {currentPage == 1
                ? Array.from({ length: 4 })?.map((eachItem, index) => {
                    return (
                      <div
                        key={"pagebtn" + index + currentPage}
                        className={`${classNames.eachBtn} ${
                          currentPage + index == currentPage
                            ? classNames.eachBtnCurrent
                            : ""
                        }`}
                        onClick={() => {
                          setCurrentPage(currentPage + index);
                        }}
                      >
                        {currentPage + index}
                      </div>
                    );
                  })
                : Array.from({ length: 4 })?.map((eachItem, index) => {
                    return (
                      <div
                        key={"pagebtn" + index + currentPage}
                        className={`${classNames.eachBtn} ${
                          currentPage + index - 1 == currentPage
                            ? classNames.eachBtnCurrent
                            : ""
                        }`}
                        onClick={() => {
                          setCurrentPage(currentPage + index - 1);
                        }}
                      >
                        {currentPage + index - 1}
                      </div>
                    );
                  })}
            </div>
            <button>Next</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Monitoring;
