export function areAllFieldsFilled(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (!obj[key]) {
        return false;
      }
    }
  }
  return true;
}

export function firstNameValidation(inputValue) {
  let regex = /^[a-zA-Z\s]*$/; // letters & spaces

  let spaces = (inputValue?.match(/ /g) || []).length;

  if (regex.test(inputValue) && spaces < 2) {
    return true;
  } else {
    return false;
  }
}

export const onlyLettersRegex = /^[A-Za-z\s\.]+$/;
