import React from "react";
import styles from "./finaceverse.module.scss";
// import logo from "../../assets/images/logo.svg";
import logo from "../../../assets/images/finace/logo-finace.svg";

const Finaceverse = () => {
  return (
    <section className={styles.circleSection}>
      <div className={styles.circle} id={styles.circle1}>
        <h3>Operational Efficiency</h3>
        <p>
          The seamless interaction between our products fosters a streamlined
          and efficient operational environment. Accountants.io connects
          practitioners with global opportunities, while Luca and Accute
          facilitate smooth task management and client communication. This
          interconnected ecosystem enhances overall productivity and service
          quality.
        </p>
      </div>
      <div className={styles.circle} id={styles.circle2}>
        <img src={logo} alt="Logo" className={styles.logo} />
        <h3>Creating The</h3>
        <h4>FinACEverse</h4>
        <p>
          Our products are more than just individual tools; they interact
          cohesively to form a comprehensive ecosystem that revolutionizes how
          accounting and tax services are delivered. The FinACEverse brings
          several key benefits to our clients
        </p>
      </div>
      <div className={styles.circle} id={styles.circle3}>
        <h3>Cost Efficiency</h3>
        <p>
          By automating routine tasks and optimizing workflows, our solutions
          significantly cut down on operational costs. The integration of
          AI-driven tools like Fin(AI)ssured and Accute ensures that resources
          are utilized effectively, reducing the need for extensive manual
          intervention.
        </p>
      </div>
      <div className={styles.circle} id={styles.circle4}>
        <h3>Enhanced Data Security</h3>
        <p>
          In today's digital age, data security is paramount. Mycabinet provides
          a robust platform for the secure exchange of sensitive documents,
          ensuring that business information is protected at all times. The
          integrated security features across our products further safeguard
          client data, giving peace of mind to both practitioners and their
          clients.
        </p>
      </div>
    </section>
  );
};

export default Finaceverse;
