import React, { useState } from "react";
import classNames from "./comparison.module.scss";

//assets
import { FaArrowRight } from "react-icons/fa";
import { comparisonItems } from "../../../assets/data/mapItems";
import { useHistory } from "react-router-dom";

const Comparison = () => {
  const navigate = useHistory();
  const [filteredItem, setFilteredItem] = useState("");

  const filterValues = [
    "All",
    "Point Solutions",
    "Human Services",
    "Combination Solutions",
    "Com",
  ];
  return (
    <section className={classNames.academyPage} id="comparison">
      <div className={classNames.academy}>
        {/* <div className={classNames.platformTitle}>#Academy</div> */}
        <div className={classNames.title}>Comparison Library</div>
        {/* <div className={classNames.desc}>
          We enhance essential accounting operations by automating and
          safeguarding all processes, from handling financial data management to
          reporting to stakeholders, ensuring accuracy and efficiency.
        </div> */}
        <div className={classNames.allFilters}>
          <input
            type="text"
            placeholder="Search"
            onChange={(event) => setFilteredItem(event?.target?.value)}
          />
          <div className={classNames.filterItems}>
            {filterValues?.length > 0 &&
              filterValues
                ?.filter((eachitem) => {
                  return eachitem
                    ?.toLowerCase()
                    .includes(filteredItem?.toLowerCase());
                })
                ?.map((eachItem, index) => {
                  return <div key={eachItem + index}>{eachItem}</div>;
                })}
          </div>
        </div>
        <div className={classNames.academyBox}>
          {comparisonItems?.map((eachItem, index) => {
            return (
              <div
                className={classNames.eachBox}
                key={"academyBox" + index}
                onClick={() => {
                  navigate.push(`/comparison/${eachItem?.title}`);
                }}
              >
                <div className={classNames.imageContainer}>
                  <img src={eachItem?.icon} alt={eachItem?.title} />
                </div>
                <div className={classNames.contentContainer}>
                  <div className={classNames.contentBox}>{eachItem?.title}</div>
                  <div className={classNames.arrowSvg}>
                    <FaArrowRight />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Comparison;
