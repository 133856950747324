import React, { useState } from 'react'
import './CpaAdminProfileCreation.scss'
import { Checkbox } from 'antd'
export default function CpaAdminProfileCreation() {
    const [list, setlist] = useState("1st")

    const swtchdata = () => {

        switch (list) {

            case "2st":
                return (
                    <div className='crpPrfilecreation'>
                        <div className='fristcontenter'>
                            <div className='coverpicktopcstom'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="49" viewBox="0 0 54 49" fill="none">
                                    <path d="M42.0472 13.3606C40.185 4.31099 31.3392 -1.51552 22.2897 0.346632C14.5407 1.94122 8.96824 8.74607 8.93269 16.6572C8.93269 17.0342 8.94827 17.4201 8.98173 17.8283C3.46899 18.4118 -0.526945 23.3538 0.0565848 28.8665C0.597455 33.9762 4.90968 37.8524 10.0479 37.8474H11.2189C11.1922 37.4771 11.1632 37.1091 11.1632 36.7321C11.1632 28.7248 17.6544 22.2335 25.6617 22.2335C33.6691 22.2335 40.1601 28.7248 40.1601 36.7321C40.1601 37.109 40.1312 37.477 40.1044 37.8474H41.2754C48.0434 37.8659 53.5449 32.3943 53.5634 25.6263C53.5812 19.1322 48.5297 13.7518 42.0472 13.3606Z" fill="#D6A86B" fill-opacity="0.25" />
                                    <path d="M25.6617 49.0003C32.4371 49.0003 37.9296 43.5077 37.9296 36.7323C37.9296 29.957 32.4371 24.4644 25.6617 24.4644C18.8863 24.4644 13.3938 29.957 13.3938 36.7323C13.3938 43.5077 18.8863 49.0003 25.6617 49.0003Z" fill="#D6A86B" />
                                    <path d="M25.6618 44.5388C25.0458 44.5388 24.5464 44.0394 24.5464 43.4235V30.0402C24.5464 29.4243 25.0458 28.9249 25.6618 28.9249C26.2777 28.9249 26.7771 29.4243 26.7771 30.0402V43.4235C26.777 44.0394 26.2777 44.5388 25.6618 44.5388Z" fill="#FAFAFA" />
                                    <path d="M30.1228 35.6174C29.8268 35.6179 29.5427 35.5008 29.3332 35.2917L25.6617 31.618L21.9902 35.2917C21.5472 35.7197 20.8411 35.7073 20.4132 35.2643C19.9957 34.8321 19.9957 34.1469 20.4132 33.7147L24.8743 29.2536C25.3097 28.8182 26.0157 28.8182 26.4513 29.2536L30.9124 33.7147C31.3473 34.1509 31.3462 34.8571 30.9099 35.2919C30.701 35.5003 30.418 35.6173 30.1228 35.6174Z" fill="#FAFAFA" />
                                </svg>
                                <div>Upload company cover photo</div>
                            </div>
                            <div className='form-data-sec'>

                                <div className='coverImage-show'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="49" viewBox="0 0 54 49" fill="none">
                                        <path d="M42.0472 13.3606C40.185 4.31099 31.3392 -1.51552 22.2897 0.346632C14.5407 1.94122 8.96824 8.74607 8.93269 16.6572C8.93269 17.0342 8.94827 17.4201 8.98173 17.8283C3.46899 18.4118 -0.526945 23.3538 0.0565848 28.8665C0.597455 33.9762 4.90968 37.8524 10.0479 37.8474H11.2189C11.1922 37.4771 11.1632 37.1091 11.1632 36.7321C11.1632 28.7248 17.6544 22.2335 25.6617 22.2335C33.6691 22.2335 40.1601 28.7248 40.1601 36.7321C40.1601 37.109 40.1312 37.477 40.1044 37.8474H41.2754C48.0434 37.8659 53.5449 32.3943 53.5634 25.6263C53.5812 19.1322 48.5297 13.7518 42.0472 13.3606Z" fill="#D6A86B" fill-opacity="0.25" />
                                        <path d="M25.6617 49.0003C32.4371 49.0003 37.9296 43.5077 37.9296 36.7323C37.9296 29.957 32.4371 24.4644 25.6617 24.4644C18.8863 24.4644 13.3938 29.957 13.3938 36.7323C13.3938 43.5077 18.8863 49.0003 25.6617 49.0003Z" fill="#D6A86B" />
                                        <path d="M25.6619 44.5388C25.046 44.5388 24.5466 44.0394 24.5466 43.4235V30.0402C24.5466 29.4243 25.046 28.9249 25.6619 28.9249C26.2779 28.9249 26.7772 29.4243 26.7772 30.0402V43.4235C26.7771 44.0394 26.2779 44.5388 25.6619 44.5388Z" fill="#FAFAFA" />
                                        <path d="M30.1229 35.6174C29.8269 35.6179 29.5428 35.5008 29.3332 35.2917L25.6617 31.618L21.9902 35.2917C21.5472 35.7197 20.8412 35.7073 20.4132 35.2643C19.9957 34.8321 19.9957 34.1469 20.4132 33.7147L24.8743 29.2536C25.3098 28.8182 26.0158 28.8182 26.4514 29.2536L30.9125 33.7147C31.3473 34.1509 31.3463 34.8571 30.91 35.2919C30.701 35.5003 30.418 35.6173 30.1229 35.6174Z" fill="#FAFAFA" />
                                    </svg></div>

                                <div className='flex-box-input'>

                                    <input placeholder='Firm display name' />
                                    <input placeholder='Firm legal name' />
                                </div>

                                <div className='flex-box-input'>

                                    <input
                                        step={{
                                            width: "100%"
                                        }}
                                        placeholder='Website link' />

                                </div>


                                <div className='flex-box-input'>

                                    <input placeholder='Colour code' />
                                    <input placeholder='Year of founding' />
                                </div>


                                <div className='flex-box-input'>

                                    <input placeholder='Phone number' />
                                    <input placeholder='Contact email' />
                                </div>

                                <div className='flex-box-input'>

                                    <textarea placeholder=" Enter description" />
                                </div>
                                <div className='name'>Business Address</div>
                                <div className='flex-box-input'>

                                    <select
                                        style={{
                                            width: "100%"
                                        }}
                                    >
                                        <option value="0">Select country</option>

                                    </select>


                                </div>
                                <div className='flex-box-input'>

                                    <select
                                        style={{
                                            width: "100%"
                                        }}
                                    >
                                        <option value="0">Select state</option>

                                    </select>


                                </div>


                                <div className='flex-box-input'>

                                    <input
                                        step={{
                                            width: "100%"
                                        }}
                                        placeholder='Address' />

                                </div>

                                <div className='flex-box-input'>

                                    <input placeholder='Apt' />
                                    <input placeholder='Postal code' />
                                </div>
                                <div className='name'>Select Specializations</div>

                                <div className='pointTabel'>
                                    <ul>
                                        <li>
                                            <div>Taxation Services:</div>
                                            <input type="checkbox" />
                                        </li>
                                        <li>
                                            <div> <span>.</span>Individual and business tax planning and compliance.</div>
                                            <input type="checkbox" />
                                        </li>
                                        <li>
                                            <div> <span>.</span>Estate and trust tax services.</div>
                                            <input type="checkbox" />
                                        </li>
                                        <li>
                                            <div> <span>.</span>International tax consulting.</div>
                                            <input type="checkbox" />
                                        </li>
                                        <li>
                                            <div>Government and Non-Profit Accounting:</div>
                                            <input type="checkbox" />
                                        </li>
                                        <li>
                                            <div> <span>.</span>Governmental auditing and compliance.</div>
                                            <input type="checkbox" />
                                        </li>

                                        <li>
                                            <div> <span>.</span>Non-profit accounting and tax services.</div>
                                            <input type="checkbox" />
                                        </li>
                                        <li>
                                            <div> <span>.</span>Grant management and reporting.</div>
                                            <input type="checkbox" />
                                        </li>
                                        <li>
                                            <div>Technology and Automation:</div>
                                            <input type="checkbox" />
                                        </li>
                                        <li>
                                            <div> <span>.</span>Implementing accounting software solutions.</div>
                                            <input type="checkbox" />
                                        </li>

                                        <li>
                                            <div> <span>.</span>Automation and process optimization.</div>
                                            <input type="checkbox" />
                                        </li>
                                        <li>
                                            <div> <span>.</span>IT consulting for accounting systems.</div>
                                            <input type="checkbox" />
                                        </li>
                                        <li>
                                            <div>Personal and Business Financial Planning:</div>
                                            <input type="checkbox" />
                                        </li>
                                        <li>
                                            <div> <span>.</span>Comprehensive financial planning for individuals.</div>
                                            <input type="checkbox" />
                                        </li>
                                        <li>
                                            <div> <span>.</span>Business succession planning.</div>
                                            <input type="checkbox" />
                                        </li>
                                        <li>
                                            <div> <span>.</span>Risk management and insurance planning.</div>
                                            <input type="checkbox" />
                                        </li>

                                    </ul>
                                </div>
                                <div className='flex-box-input'
                                    style={{
                                        marginTop: "4rem"
                                    }}
                                >

                                    <label
                                        onClick={() => setlist("1st")}
                                    >Go Back</label>
                                    <label
                                        style={{
                                            background: "#191F41"
                                        }}

                                    >

                                        Next</label>
                                </div>
                            </div>
                        </div></div>
                )
                break;
            case "1st":
                return (
                    <div className='crpPrfilecreation'>
                        <div className='fristcontenter'>
                            <div className='coverpicktop'>

                            </div>


                            <div className='form-data-sec'>
                                <div className='coverImage'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="49" viewBox="0 0 54 49" fill="none">
                                        <path d="M42.0472 13.3606C40.185 4.31099 31.3392 -1.51552 22.2897 0.346632C14.5407 1.94122 8.96824 8.74607 8.93269 16.6572C8.93269 17.0342 8.94827 17.4201 8.98173 17.8283C3.46899 18.4118 -0.526945 23.3538 0.0565848 28.8665C0.597455 33.9762 4.90968 37.8524 10.0479 37.8474H11.2189C11.1922 37.4771 11.1632 37.1091 11.1632 36.7321C11.1632 28.7248 17.6544 22.2335 25.6617 22.2335C33.6691 22.2335 40.1601 28.7248 40.1601 36.7321C40.1601 37.109 40.1312 37.477 40.1044 37.8474H41.2754C48.0434 37.8659 53.5449 32.3943 53.5634 25.6263C53.5812 19.1322 48.5297 13.7518 42.0472 13.3606Z" fill="#D6A86B" fill-opacity="0.25" />
                                        <path d="M25.6617 49.0003C32.4371 49.0003 37.9296 43.5077 37.9296 36.7323C37.9296 29.957 32.4371 24.4644 25.6617 24.4644C18.8863 24.4644 13.3938 29.957 13.3938 36.7323C13.3938 43.5077 18.8863 49.0003 25.6617 49.0003Z" fill="#D6A86B" />
                                        <path d="M25.6619 44.5388C25.046 44.5388 24.5466 44.0394 24.5466 43.4235V30.0402C24.5466 29.4243 25.046 28.9249 25.6619 28.9249C26.2779 28.9249 26.7772 29.4243 26.7772 30.0402V43.4235C26.7771 44.0394 26.2779 44.5388 25.6619 44.5388Z" fill="#FAFAFA" />
                                        <path d="M30.1229 35.6174C29.8269 35.6179 29.5428 35.5008 29.3332 35.2917L25.6617 31.618L21.9902 35.2917C21.5472 35.7197 20.8412 35.7073 20.4132 35.2643C19.9957 34.8321 19.9957 34.1469 20.4132 33.7147L24.8743 29.2536C25.3098 28.8182 26.0158 28.8182 26.4514 29.2536L30.9125 33.7147C31.3473 34.1509 31.3463 34.8571 30.91 35.2919C30.701 35.5003 30.418 35.6173 30.1229 35.6174Z" fill="#FAFAFA" />
                                    </svg></div>
                                <div className='flex-box-input'>

                                    <input placeholder='First name' />
                                    <input placeholder='Last name' />
                                </div>
                                <div className='flex-box-input'>

                                    <input placeholder='Email' />
                                    <input placeholder='Phone number' />
                                </div>
                                <div className='flex-box-input'>

                                    <select>
                                        <option value="0">Select country</option>

                                    </select>

                                    <select>
                                        <option value="0">Select state</option>

                                    </select>
                                </div>

                                <div className='flex-box-input'>

                                    <input
                                        step={{
                                            width: "100%"
                                        }}
                                        placeholder='Address' />

                                </div>

                                <div className='flex-box-input'>

                                    <input placeholder='Apt' />
                                    <input placeholder='Postal code' />
                                </div>

                                <div className='flex-box-input'>

                                    <label>Go Back</label>
                                    <label
                                        style={{
                                            background: "#191F41"
                                        }}

                                        onClick={() => setlist("2st")}
                                    >

                                        Next</label>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                break;

            default:
                break;
        }
    }
    return (
        <div>
            {swtchdata()}
        </div>
    )
}
