import React, { useContext, useEffect, useState } from "react";
import classNames from "./registration.module.scss";

//assets
import fullLogo from "../../assets/images/logo.svg";
import { registrationItems } from "../../assets/data/mapItems";
import { Link, useHistory } from "react-router-dom";
import {
  confirmEmailWithOTP,
  preRegistrationLogin,
  preRegistrationUpdateUserPassword,
} from "../../assets/functions/authentication";
import { toastify } from "../../assets/functions/toastify";
import dummyProfile from "../../assets/images/registration/dummyprofile.svg";
import axios from "axios";
import { GlobalContext } from "../../context/Globalcontext";
import OtpInput from "react-otp-input";

const Registration = () => {
  const navigate = useHistory();
  //page values

  const { setGlobalLoading } = useContext(GlobalContext);
  const [emailCredentials, setEmailCredentials] = useState("");
  const [passwordCredentials, setPasswordCredentials] = useState("");
  const [resetPassword, setResetPassword] = useState({});
  const [registrationStep, setRegistrationStep] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [sessionResponse, setSessionResponse] = useState("");

  const [userOtp, setUserOtp] = useState("");

  //via affiliate
  const [affiliateUser, setAffiliateUser] = useState({
    username: "",
    email: "",
    password: "",
    ref_affiliate: "",
    account_type: "Personal",
    signedup_app: "finace",
  });
  const [allAffiliateTemp, setAllAffiliateTemp] = useState("");
  const [affiliateProfileData, setAffiliateProfileData] = useState("");

  async function updatePasswordAPI() {
    setIsLoggingIn(true);
    let response = await preRegistrationUpdateUserPassword(
      sessionResponse?.email,
      resetPassword?.confirm_password,
      sessionResponse?.authChallenge_id,
      sessionResponse?.username,
      sessionResponse?.challengeName,
      sessionResponse?.session
    );
    setIsLoggingIn(true);
    if (
      response?.status === true &&
      response?.message === "Successfully Logged In"
    ) {
      toastify("Password updated successfully!", "success");
      setTimeout(() => {
        navigate.push("/login");
      }, 3000);
    } else {
      toastify("Error while updating password", "error");
    }
    setIsLoggingIn(false);
  }

  async function confirmEmailOTP() {
    if (registrationStep?.includes("Myself")) {
      setRegistrationStep("Myself-Step-Success");
    } else {
      setRegistrationStep("Affiliate-Step-Success");
    }

    setIsLoggingIn(true);
    setGlobalLoading(true);
    let response = await confirmEmailWithOTP(affiliateUser?.email, userOtp);
    if (response?.status === true) {
      toastify("User registration successful!", "success");
    } else {
      toastify("OTP entered is wrong!", "error");
    }
    setIsLoggingIn(false);
    setGlobalLoading(false);
    setTimeout(() => {
      navigate.push("/");
    }, 3000);
  }

  async function getAffiliateProfile(queryTypeValue, type) {
    setIsLoggingIn(true);
    let response = await axios.get(
      `https://comms.globalxchange.io/user/profile/data/get?${queryTypeValue}`
    );
    console.log(response?.data, "getAffiliateProfile");
    if (type === "isExist" && response?.data?.count > 0) {
      setAllAffiliateTemp((prev) => {
        return { ...prev, isExist: true };
      });
    } else if (type === "isExist") {
      setAllAffiliateTemp((prev) => {
        return { ...prev, isExist: false };
      });
    } else if (type !== "isExist") {
      setAffiliateProfileData(response?.data?.usersData[0]);
      setRegistrationStep("Affiliate-Step1");
    }
    setIsLoggingIn(false);
  }

  function isValidEmail(email) {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regex
    return emailRegex.test(email);
  }

  function userRegistration() {
    if (registrationStep?.includes("Myself")) {
      affiliateUser.ref_affiliate = "6535e2f65958ffca65b865ef21fde26f0b8406ca";
    }
    setIsLoggingIn(true);
    axios
      .post("https://gxauth.apimachine.com/gx/user/signup", affiliateUser)
      .then((response) => {
        // console.log(response, "userRegistration");
        // if (response?.data?.status) {
        //   toastify("User registration successful!", "success");

        //   setTimeout(() => {
        //     navigate.push("/login");
        //   }, 2000);
        // } else {
        //   toastify(response?.data?.message, "error");
        // }
        // setIsLoggingIn(false);

        console.log(response, "userRegistration");
        if (response?.data?.status) {
          if (registrationStep?.includes("Myself")) {
            setRegistrationStep("Myself-Step4");
          } else {
            setRegistrationStep("Affiliate-Step6");
          }
          // toastify("User registration successful!", "success");

          // setTimeout(() => {
          //   navigate.push("/");
          // }, 2000);
        } else {
          toastify(response?.data?.message, "error");
        }
        setGlobalLoading(false);
        setIsLoggingIn(false);
      })
      .catch((error) => {
        console.log(error?.message, "userRegistration");
        toastify("User registration failed!", "error");
        setIsLoggingIn(false);
      });
  }

  // Debouncing logic using useEffect
  useEffect(() => {
    let debounceTimer;
    if (
      registrationStep === "Affiliate-Step2" ||
      registrationStep === "Myself-Step"
    ) {
      debounceTimer = setTimeout(() => {
        getAffiliateProfile("email=" + affiliateUser?.email, "isExist");
      }, 100); // 100 milliseconds
    } else if (
      registrationStep === "Affiliate-Step3" ||
      registrationStep === "Myself-Step1"
    ) {
      debounceTimer = setTimeout(() => {
        getAffiliateProfile("username=" + affiliateUser?.username, "isExist");
      }, 100); // 100 milliseconds
    }

    return () => clearTimeout(debounceTimer); // Cleanup on unmount or input change
  }, [affiliateUser?.email, affiliateUser?.username]);

  return (
    <div className={classNames.loginPage}>
      <div className={classNames.topNav}>
        <Link to="/login">Go Back To Login</Link>
      </div>
      <div className={classNames.loginCard}>
        {registrationStep === "Pre-Registered" ? (
          <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
            <img
              src={fullLogo}
              alt="fullLogo"
              onClick={() => navigate.push("/")}
            />
            <div className={classNames.title}>
              Enter the temporary credentials you have received.
            </div>
            <div className={classNames.credentialsContainer}>
              <input
                className={classNames.inputContainer}
                placeholder="Email"
                type="email"
                value={emailCredentials}
                onChange={(event) => {
                  event.persist();
                  setEmailCredentials(event?.target.value);
                }}
              />
              <input
                className={classNames.inputContainer}
                placeholder="Password"
                type="password"
                value={passwordCredentials}
                onChange={(event) => {
                  event.persist();
                  setPasswordCredentials(event?.target.value);
                }}
                onKeyDown={async (event) => {
                  if (event.key === "Enter") {
                    setIsLoggingIn(true);
                    let response = await preRegistrationLogin(
                      emailCredentials,
                      passwordCredentials
                    );
                    setIsLoggingIn(false);
                    setSessionResponse(response);
                  }
                }}
              />
              <div className={classNames.buttonsContainer}>
                <button
                  className={classNames.blueBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => setRegistrationStep("")}
                >
                  Back
                </button>
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={async () => {
                    setIsLoggingIn(true);
                    let response = await preRegistrationLogin(
                      emailCredentials,
                      passwordCredentials
                    );
                    setIsLoggingIn(false);
                    if (
                      response?.message === "Reset User Password!" ||
                      response?.challengeName === "NEW_PASSWORD_REQUIRED"
                    ) {
                      setRegistrationStep("Pre-Registered-Step1");
                    }
                    setSessionResponse(response);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : registrationStep === "Pre-Registered-Step1" ? (
          <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
            <img src={fullLogo} alt="fullLogo" />
            <div className={classNames.title}>
              Create your new permanent password
            </div>
            <div
              className={classNames.credentialsContainer}
              style={{ minWidth: "550px" }}
            >
              <input
                className={classNames.inputContainer}
                placeholder="Password"
                type="password"
                value={resetPassword?.password}
                onChange={(event) => {
                  event.persist();
                  setResetPassword((prev) => {
                    return { ...prev, password: event?.target.value };
                  });
                }}
                onKeyDown={async (event) => {
                  if (event.key === "Enter") {
                    setRegistrationStep("Pre-Registered-Step2");
                  }
                }}
              />
              <div className={classNames.buttonsContainer}>
                <button
                  className={classNames.blueBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => setRegistrationStep("Pre-Registered")}
                >
                  Back
                </button>
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => {
                    setRegistrationStep("Pre-Registered-Step2");
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : registrationStep === "Pre-Registered-Step2" ||
          registrationStep === "Password dosen't match!" ? (
          <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
            <img src={fullLogo} alt="fullLogo" />
            <div className={classNames.title}>Confirm your new password</div>
            <div
              className={classNames.credentialsContainer}
              style={{ minWidth: "550px" }}
            >
              <input
                className={classNames.inputContainer}
                placeholder="Password"
                type="password"
                value={resetPassword?.confirm_password}
                onChange={(event) => {
                  event.persist();
                  setResetPassword((prev) => {
                    return { ...prev, confirm_password: event?.target.value };
                  });
                }}
                onKeyDown={async (event) => {
                  if (event.key === "Enter") {
                    if (
                      resetPassword?.password ===
                      resetPassword?.confirm_password
                    ) {
                      updatePasswordAPI();
                    } else {
                      setRegistrationStep("Password dosen't match!");
                    }
                  }
                }}
              />
              <div
                className={classNames.message}
                style={{
                  display:
                    registrationStep === "Password dosen't match!"
                      ? ""
                      : "none",
                }}
              >
                {registrationStep}
              </div>
              <div className={classNames.buttonsContainer}>
                <button
                  className={classNames.blueBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => setRegistrationStep("Pre-Registered-Step1")}
                >
                  Back
                </button>
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={async () => {
                    if (
                      resetPassword?.password ===
                      resetPassword?.confirm_password
                    ) {
                      updatePasswordAPI();
                    } else {
                      setRegistrationStep("Password does not match!");
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : registrationStep === "Affiliate" ? (
          <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
            <img
              src={fullLogo}
              alt="fullLogo"
              onClick={() => navigate.push("/")}
            />
            <div className={classNames.title}>Who referred you to Finace?</div>
            <div className={classNames.credentialsContainer}>
              <input
                className={classNames.inputContainer}
                placeholder="Their Username..."
                type="text"
                value={allAffiliateTemp?.their_username}
                onChange={(event) => {
                  event.persist();
                  setAllAffiliateTemp((prev) => {
                    return { ...prev, their_username: event?.target?.value };
                  });
                }}
              />
              <div className={classNames.seperator}>
                <span>OR</span>
              </div>
              <input
                className={classNames.inputContainer}
                placeholder="Their Email..."
                type="email"
                value={allAffiliateTemp?.their_email}
                onChange={(event) => {
                  event.persist();
                  setAllAffiliateTemp((prev) => {
                    return { ...prev, their_email: event?.target?.value };
                  });
                }}
              />
              <div className={classNames.buttonsContainer}>
                <button
                  className={classNames.blueBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => setRegistrationStep("")}
                >
                  Back
                </button>
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => {
                    if (
                      allAffiliateTemp?.their_email &&
                      isValidEmail(allAffiliateTemp?.their_email)
                    ) {
                      getAffiliateProfile(
                        "email=" + allAffiliateTemp?.their_email
                      );
                    } else if (allAffiliateTemp?.their_username) {
                      getAffiliateProfile(
                        "username=" + allAffiliateTemp?.their_username
                      );
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : registrationStep === "Affiliate-Step1" ? (
          <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
            <img
              src={fullLogo}
              alt="fullLogo"
              onClick={() => navigate.push("/")}
            />
            <div className={classNames.title}>
              Please confirm this is the person who referred you?
            </div>
            <div className={classNames.credentialsContainer}>
              <div className={classNames.profileContainer}>
                <div>
                  <img
                    src={
                      affiliateProfileData?.dynamic?.length > 0 &&
                      affiliateProfileData?.dynamic[0]?.data?.profile_img
                        ? affiliateProfileData?.profile_img
                        : dummyProfile
                    }
                    alt=""
                  />
                </div>
                <div>
                  <div>
                    {affiliateProfileData?.hardCoded?.length > 0 &&
                    affiliateProfileData?.hardCoded[0]?.data?.username
                      ? affiliateProfileData?.hardCoded[0]?.data?.username
                      : ""}
                  </div>
                  <div>
                    {affiliateProfileData?.email
                      ? affiliateProfileData?.email
                      : ""}
                  </div>
                </div>
              </div>
              <div className={classNames.buttonsContainer}>
                <button
                  className={classNames.blueBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => setRegistrationStep("Affiliate")}
                >
                  No
                </button>
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => {
                    setAffiliateUser((prev) => {
                      return {
                        ...prev,
                        ref_affiliate: affiliateProfileData?.username
                          ? affiliateProfileData?.username
                          : "",
                      };
                    });
                    setRegistrationStep("Affiliate-Step2");
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        ) : registrationStep === "Affiliate-Step2" ? (
          <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
            <img
              src={fullLogo}
              alt="fullLogo"
              onClick={() => navigate.push("/")}
            />
            <div className={classNames.title}>What is your email?</div>
            <div className={classNames.credentialsContainer}>
              <div className={classNames.isExistContainer}>
                <input
                  className={classNames.inputContainer}
                  placeholder="Email"
                  type="email"
                  value={affiliateUser?.email}
                  onChange={(event) => {
                    event.persist();
                    setAffiliateUser((prev) => {
                      return { ...prev, email: event?.target?.value };
                    });
                  }}
                />
                <div
                  className={classNames.isExistLight}
                  style={{
                    background: allAffiliateTemp?.isExist
                      ? ""
                      : "var(--main-color)",
                  }}
                ></div>
              </div>
              <div className={classNames.buttonsContainer}>
                <button
                  className={classNames.blueBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => setRegistrationStep("Affiliate-Step1")}
                >
                  Back
                </button>
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => {
                    if (!allAffiliateTemp?.isExist) {
                      setAllAffiliateTemp((prev) => {
                        return { ...prev, isExist: false };
                      });
                      setRegistrationStep("Affiliate-Step3");
                    } else {
                      toastify("Email already exist", "error");
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : registrationStep === "Affiliate-Step3" ? (
          <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
            <img
              src={fullLogo}
              alt="fullLogo"
              onClick={() => navigate.push("/")}
            />
            <div className={classNames.title}>Create a Finace username</div>
            <div className={classNames.credentialsContainer}>
              <div className={classNames.isExistContainer}>
                <input
                  className={classNames.inputContainer}
                  placeholder="Username"
                  type="text"
                  value={affiliateUser?.username}
                  onChange={(event) => {
                    event.persist();
                    setAffiliateUser((prev) => {
                      return { ...prev, username: event?.target?.value };
                    });
                  }}
                />
                <div
                  className={classNames.isExistLight}
                  style={{
                    background: allAffiliateTemp?.isExist
                      ? ""
                      : "var(--main-color)",
                  }}
                ></div>
              </div>

              <div className={classNames.buttonsContainer}>
                <button
                  className={classNames.blueBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => setRegistrationStep("Affiliate-Step2")}
                >
                  Back
                </button>
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => {
                    if (!allAffiliateTemp?.isExist) {
                      setAllAffiliateTemp((prev) => {
                        return { ...prev, isExist: false };
                      });
                      setRegistrationStep("Affiliate-Step4");
                    } else {
                      toastify("Username already exist", "error");
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : registrationStep === "Affiliate-Step4" ? (
          <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
            <img
              src={fullLogo}
              alt="fullLogo"
              onClick={() => navigate.push("/")}
            />
            <div className={classNames.title}>Enter your desired password</div>
            <div className={classNames.credentialsContainer}>
              <input
                className={classNames.inputContainer}
                placeholder="Password"
                type="password"
                value={allAffiliateTemp?.password}
                onChange={(event) => {
                  event.persist();
                  setAllAffiliateTemp((prev) => {
                    return { ...prev, password: event?.target?.value };
                  });
                }}
              />
              <div className={classNames.buttonsContainer}>
                <button
                  className={classNames.blueBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => setRegistrationStep("Affiliate-Step3")}
                >
                  Back
                </button>
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => setRegistrationStep("Affiliate-Step5")}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : registrationStep === "Affiliate-Step5" ? (
          <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
            <img
              src={fullLogo}
              alt="fullLogo"
              onClick={() => navigate.push("/")}
            />
            <div className={classNames.title}>Please confirm your password</div>
            <div className={classNames.credentialsContainer}>
              <input
                className={classNames.inputContainer}
                placeholder="Password"
                type="password"
                value={affiliateUser?.password}
                onChange={(event) => {
                  event.persist();
                  setAffiliateUser((prev) => {
                    return { ...prev, password: event?.target?.value };
                  });
                }}
              />
              <div className={classNames.buttonsContainer}>
                <button
                  className={classNames.blueBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => setRegistrationStep("Affiliate-Step4")}
                >
                  Back
                </button>
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => {
                    if (
                      allAffiliateTemp?.password === affiliateUser?.password
                    ) {
                      userRegistration();
                      // console.log(affiliateUser, "affiliate user data");
                    } else {
                      // setRegistrationStep("Affiliate-Step5");
                      toastify("Passwords dosen't match!", "error");
                      console.log(affiliateUser, "affiliate user failed");
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : registrationStep === "Affiliate-Step6" ? (
          <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
            <img src={fullLogo} alt="fullLogo" />
            <div className={classNames.title}>
              Enter The Code That Was Just Sent to you.{" "}
              {affiliateUser?.email ? affiliateUser?.email : ""}
            </div>
            <div
              className={classNames.credentialsContainer}
              style={{ minWidth: "550px" }}
            >
              <div className={classNames.otpInputParent}>
                <OtpInput
                  value={userOtp}
                  onChange={setUserOtp}
                  numInputs={6}
                  separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                  shouldAutoFocus
                  containerStyle={classNames.otpInputWrapper}
                  inputStyle={classNames.otpInput}
                />
              </div>
              <div
                className={classNames.message}
                style={{
                  display:
                    registrationStep === "OTP entered is wrong!" ? "" : "none",
                }}
              >
                {registrationStep}
              </div>
              <div className={classNames.buttonsContainer}>
                <button
                  className={classNames.blueBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  // onClick={() => setRegistrationStep("Pre-Registered-Step1")}
                >
                  Resend Code
                </button>
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={async () => {
                    console.log(userOtp, "userOtp");
                    if (userOtp?.length === 6) {
                      confirmEmailOTP();
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : registrationStep === "Affiliate-Step-Success" ? (
          <div
            className={classNames.leftCard}
            style={{ paddingRight: "3rem", alignItems: "center" }}
          >
            <img src={fullLogo} alt="fullLogo" style={{ margin: "6rem 0" }} />
            <div className={classNames.title}>
              Your Account Is Being Created
            </div>
          </div>
        ) : registrationStep === "Myself-Step" ? (
          <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
            <img
              src={fullLogo}
              alt="fullLogo"
              onClick={() => navigate.push("/")}
            />
            <div className={classNames.title}>What is your email?</div>
            <div className={classNames.credentialsContainer}>
              <div className={classNames.isExistContainer}>
                <input
                  className={classNames.inputContainer}
                  placeholder="Email"
                  type="email"
                  value={affiliateUser?.email}
                  onChange={(event) => {
                    event.persist();
                    setAffiliateUser((prev) => {
                      return { ...prev, email: event?.target?.value };
                    });
                  }}
                />
                <div
                  className={classNames.isExistLight}
                  style={{
                    background: allAffiliateTemp?.isExist
                      ? ""
                      : "var(--main-color)",
                  }}
                ></div>
              </div>
              <div className={classNames.buttonsContainer}>
                <button
                  className={classNames.blueBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => setRegistrationStep("")}
                >
                  Back
                </button>
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => {
                    if (!allAffiliateTemp?.isExist) {
                      setAllAffiliateTemp((prev) => {
                        return { ...prev, isExist: false };
                      });
                      setRegistrationStep("Myself-Step1");
                    } else {
                      toastify("Email already exist", "error");
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : registrationStep === "Myself-Step1" ? (
          <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
            <img
              src={fullLogo}
              alt="fullLogo"
              onClick={() => navigate.push("/")}
            />
            <div className={classNames.title}>Create a Finace username</div>
            <div className={classNames.credentialsContainer}>
              <div className={classNames.isExistContainer}>
                <input
                  className={classNames.inputContainer}
                  placeholder="Username"
                  type="text"
                  value={affiliateUser?.username}
                  onChange={(event) => {
                    event.persist();
                    setAffiliateUser((prev) => {
                      return { ...prev, username: event?.target?.value };
                    });
                  }}
                />
                <div
                  className={classNames.isExistLight}
                  style={{
                    background: allAffiliateTemp?.isExist
                      ? ""
                      : "var(--main-color)",
                  }}
                ></div>
              </div>

              <div className={classNames.buttonsContainer}>
                <button
                  className={classNames.blueBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => setRegistrationStep("Myself-Step")}
                >
                  Back
                </button>
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => {
                    if (!allAffiliateTemp?.isExist) {
                      setAllAffiliateTemp((prev) => {
                        return { ...prev, isExist: false };
                      });
                      setRegistrationStep("Myself-Step2");
                    } else {
                      toastify("Username already exist", "error");
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : registrationStep === "Myself-Step2" ? (
          <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
            <img
              src={fullLogo}
              alt="fullLogo"
              onClick={() => navigate.push("/")}
            />
            <div className={classNames.title}>Enter your desired password</div>
            <div className={classNames.credentialsContainer}>
              <input
                className={classNames.inputContainer}
                placeholder="Password"
                type="password"
                value={allAffiliateTemp?.password}
                onChange={(event) => {
                  event.persist();
                  setAllAffiliateTemp((prev) => {
                    return { ...prev, password: event?.target?.value };
                  });
                }}
              />
              <div className={classNames.buttonsContainer}>
                <button
                  className={classNames.blueBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => setRegistrationStep("Myself-Step1")}
                >
                  Back
                </button>
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => setRegistrationStep("Myself-Step3")}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : registrationStep === "Myself-Step3" ? (
          <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
            <img
              src={fullLogo}
              alt="fullLogo"
              onClick={() => navigate.push("/")}
            />
            <div className={classNames.title}>Please confirm your password</div>
            <div className={classNames.credentialsContainer}>
              <input
                className={classNames.inputContainer}
                placeholder="Password"
                type="password"
                value={affiliateUser?.password}
                onChange={(event) => {
                  event.persist();
                  setAffiliateUser((prev) => {
                    return { ...prev, password: event?.target?.value };
                  });
                }}
              />
              <div className={classNames.buttonsContainer}>
                <button
                  className={classNames.blueBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => setRegistrationStep("Myself-Step2")}
                >
                  Back
                </button>
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => {
                    if (
                      allAffiliateTemp?.password === affiliateUser?.password
                    ) {
                      userRegistration();
                      // console.log(affiliateUser, "affiliate user data");
                    } else {
                      // setRegistrationStep("Affiliate-Step5");
                      toastify("Passwords dosen't match!", "error");
                      console.log(affiliateUser, "affiliate user failed");
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : registrationStep === "Myself-Step4" ? (
          <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
            <img src={fullLogo} alt="fullLogo" />
            <div className={classNames.title}>
              Enter The Code That Was Just Sent To You{" "}
              {affiliateUser?.email ? affiliateUser?.email : ""}
            </div>
            <div
              className={classNames.credentialsContainer}
              style={{ minWidth: "550px" }}
            >
              <div className={classNames.otpInputParent}>
                <OtpInput
                  value={userOtp}
                  onChange={setUserOtp}
                  numInputs={6}
                  separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                  shouldAutoFocus
                  containerStyle={classNames.otpInputWrapper}
                  inputStyle={classNames.otpInput}
                />
              </div>
              <div
                className={classNames.message}
                style={{
                  display:
                    registrationStep === "OTP entered is wrong!" ? "" : "none",
                }}
              >
                {registrationStep}
              </div>
              <div className={classNames.buttonsContainer}>
                <button
                  className={classNames.blueBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  // onClick={() => setRegistrationStep("Pre-Registered-Step1")}
                >
                  Resend Code
                </button>
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={async () => {
                    console.log(userOtp, "userOtp");
                    if (userOtp?.length === 6) {
                      confirmEmailOTP();
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : registrationStep === "Myself-Step-Success" ? (
          <div
            className={classNames.leftCard}
            style={{ paddingRight: "3rem", alignItems: "center" }}
          >
            <img src={fullLogo} alt="fullLogo" style={{ margin: "6rem 0" }} />
            <div className={classNames.title}>
              Your Account Is Being Created
            </div>
          </div>
        ) : (
          <div className={classNames.leftCard}>
            <img src={fullLogo} alt="fullLogo" />
            <div className={classNames.title}>How did you hear about us?</div>
            <div className={classNames.allRegistrationOptions}>
              {registrationItems?.map((eachItem, index) => {
                return (
                  <div
                    className={classNames.eachOption}
                    key={eachItem?.title + index}
                    onClick={() => {
                      if (eachItem?.title?.includes("Pre-Registered")) {
                        setRegistrationStep("Pre-Registered");
                      } else if (eachItem?.title?.includes("Affiliate")) {
                        setRegistrationStep("Affiliate");
                      } else if (eachItem?.title?.includes("Myself")) {
                        setRegistrationStep("Myself-Step");
                      }
                    }}
                  >
                    <div className={classNames.imageContainer}>
                      <img src={eachItem?.image} alt={eachItem?.title} />
                    </div>
                    <div className={classNames.title}>
                      <div>{eachItem?.title}</div>
                      <div>{eachItem?.desc}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Registration;
