import axios from "axios";

export async function preRegistrationLogin(email, password) {
  let response = await axios.post(
    "https://gxauth.apimachine.com/gx/user/auth/login",
    {
      email: email,
      password: password,
    }
  );

  return response?.data;
}

export async function confirmEmailWithOTP(email, code) {
  let response = await axios.post(
    "https://gxauth.apimachine.com/gx/user/confirm",
    {
      email: email,
      code: code,
    }
  );

  return response?.data;
}

export async function preRegistrationUpdateUserPassword(
  email,
  password,
  authChallenge_id,
  username,
  challengeName,
  session
) {
  let response = await axios.post(
    "https://gxauth.apimachine.com/gx/user/auth/login/challenge",
    {
      email: email,
      newPassword: password,
      authChallenge_id,
      username,
      challengeName,
      session,
    }
  );

  return response?.data;
}
