import React, { useState } from "react";
import classNames from "./common.module.scss";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { Link } from "react-router-dom";

const SideMenu = () => {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  return (
    <>
      <div
        className={classNames.sideMenuShowHide}
        onClick={() => setSideMenuOpen((prev) => !prev)}
      >
        {sideMenuOpen ? <IoIosArrowDropleft /> : <IoIosArrowDropright />}
      </div>
      {sideMenuOpen && (
        <div className={classNames.sideMenuTypesFull}>
          <div className={classNames.sideMenuTypes}>
            <Link to="/admin/crm/users">
              <div
                style={{
                  color:
                    window?.location?.pathname?.includes("/crm/users") &&
                    "#fff",
                  background:
                    window?.location?.pathname?.includes("/crm/users") &&
                    "#D6A86B",
                }}
              >
                User Management
              </div>
            </Link>
            <Link to="/admin/crm/monitoring">
              <div
                style={{
                  color:
                    window?.location?.pathname?.includes("/crm/monitoring") &&
                    "#fff",
                  background:
                    window?.location?.pathname?.includes("/crm/monitoring") &&
                    "#D6A86B",
                }}
              >
                Monitoring
              </div>
            </Link>
            <Link to="/admin/crm/users">
              <div>Role Management</div>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default SideMenu;
