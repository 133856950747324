import React, { useState } from "react";
import styles from "./tsk.module.scss";
import { BsPlusCircleFill } from "react-icons/bs";
const TaskManager = () => {
  const tabs = ["By Client", "By Service", "By Client & Service", "Other"];
  const [selectedTab, setSelectedTab] = useState("By Client");

  const boardData = [
    {
      title: "To Do",
      count: 2,
    },
    {
      title: "In Progress",
      count: 1,
    },
    {
      title: "Waiting On Client",
      count: 4,
    },
    {
      title: "Completed",
      count: 3,
    },
  ];

  return (
    <div className={styles.container}>
      <h1 style={{ padding: "0px 2.8rem" }}>Task manager</h1>
      <div className={styles.tabWrapper}>
        {tabs.map((item) => (
          <div
            className={selectedTab == item ? styles.selectedTab : ""}
            onClick={(e) => setSelectedTab(item)}
          >
            {item}
          </div>
        ))}
      </div>
      <div className={styles.clientDetails}>
        <div className={styles.clientCard}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className={styles.avatar}></div>
            <div>
              <div className={styles.clientName}>Shorupan Pirakaspathy</div>
              <div className={styles.clientEmail}>shorupan@gmail.com</div>
            </div>
          </div>
          <div className={styles.changeClientBtn}>Change Client</div>
        </div>
      </div>

      <div className={styles.boardWrapper}>
        {boardData.map((item) => (
          <div>
            <div className={styles.boardTitle}>
              <div>
                {item?.title}&nbsp;({item.count})
              </div>
              <BsPlusCircleFill />
            </div>
            <div style={{ overflowY: "scroll", height: "40vh" }}>
              {Array(item.count)
                .fill("")
                .map((item1) => (
                  <div className={styles.cardWrapper}>&nbsp;</div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaskManager;
